import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";

const link = createHttpLink({
   uri: "https://saarang.org/api2/",
     //uri: "http://localhost:8001/graphql",
    credentials: "include"
  });
  
  const client = new ApolloClient({
    link: link,
    cache: new InMemoryCache(),
    credentials: 'include'
  });

  export default client;