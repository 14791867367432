import React, { } from 'react';
import './App.scss';
import Events from './pages/events/events';
import Event_Desp from './pages/event_desp/event_desp';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Sponsors from './pages/sponsors/sponsors';
import Footer from './components/footer/footer';
import PutEvents from './pages/put-editEvent/putEvents';
import ContactUs from './pages/contact/contact';
import VerifyEmail from './pages/verifyEmail/VerifyEmail';
import Schedule from './pages/schedule/Schedule';
import EventCategory from './pages/homepage/event_category/event_category';
import RegisteredEvents from './pages/registered_events/registered_events';
import EditEvent from './pages/put-editEvent/edit_event';
import GetRegistered from './pages/get_registered/get_registered';
import ClubPassSales from './pages/clubsales/clubpass_sales';
import Rules from './pages/rules/rules';
import Policy from './pages/policy/policy';
import Loading from './pages/loadingvid/loadingvid';
import Homepage from './pages/homepage/homepage';
import Sidebar from './components/sidebar/Sidebar';
import Tbd from './pages/tbd/tbd';
import SilentDisco from './pages/silentdisco/silentdisco';
import Sdsales from './pages/silentdisco_sales/sd_sales';
import Map from './components/map/map';

function App() {

  return (
    <div className=''>
      <BrowserRouter>
        <Sidebar/>
        <Routes>
          <Route path="/" element={ <Loading />}/>
          <Route path="/home" element={<Homepage />} />
          <Route path="/verifyEmail/:id1/:id2" element={<VerifyEmail />} />
          <Route path="/eventcategories" element={<EventCategory />} />
          <Route path="/events/:id" element={ <Events/> } />
          <Route path="/event/:id" element={<Event_Desp></Event_Desp>}/>
          <Route path="/registeredevents" element={<RegisteredEvents />} />
          <Route path="/sponsors" element={<Sponsors />} />
          <Route path="/schedule" element={<Schedule />} />
          <Route path="/addevents" element={<PutEvents />} />
          <Route path="/editevents" element={<EditEvent />} />
          <Route path='getregistered' element={<GetRegistered />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/clubpasssales" element={<ClubPassSales />} />
          <Route path="/tbd" element={<Tbd/>}/>
          <Route path="/rules" element={<Rules/>} />
          <Route path="/policy" element={<Policy/>} />
          <Route path="/silentdisco" element={<SilentDisco/>} />
          <Route path="/silentdisco_sales" element={<Sdsales/>} />
          <Route path="/map" element={<Map/>}/>
        </Routes>
        <Footer />
      </BrowserRouter> 
    </div>
  );
}

export default App;
