import React, { useRef, useState } from 'react'
import { CSVLink } from 'react-csv'
import './get_registered.css'
import { useGetAllEventsQuery, useGetEventbyIdLazyQuery, useGetRegisteredUsersForEventLazyQuery } from '../../generated/graphql'

const GetRegistered = () => {

    const [getRegisteredUsers, { data: eventRegData, loading: eventRegLoading, error: eventRegError }] = useGetRegisteredUsersForEventLazyQuery({
        'fetchPolicy': 'network-only',
    })

    const { data: eventsData, loading: eventsLoading, error: eventsError } = useGetAllEventsQuery({
    });

    const eventGet = useRef<null | HTMLInputElement>(null);
    const [eventName, setEventName] = useState<string>('');
    const [csvData, setCsvData] = useState<any>();

    const handleGetRegistered = () => {
        const eventRef = eventGet.current;
        var event = document.getElementById(eventRef ? eventRef.value : 'DUMMYID');
        setEventName(eventRef ? eventRef.value : 'registered_users');
        getRegisteredUsers({
            variables: {
                'eventId': event?.dataset.value ? event?.dataset.value : '1',
            }
        })
    }

    React.useEffect(() => {
        if (eventRegData) {
            setCsvData(eventRegData.getRegisteredUsersForEvent);
        }
    }, [eventRegData])




    return (
        <div className='register-form'>
            <div className='get-edit-event' style={{ 'paddingBottom': '400px' }} >
                <div className='get-data-box'>
                    <label>EVENT NAME</label>
                    <input list='events' required ref={eventGet}></input>
                    <datalist id="events">
                        {eventsData?.getAllEvents.map((event, index) => {
                            return <option value={event.title} id={event.title} data-value={event.id} ></option>
                        })}
                    </datalist>
                    <button onClick={handleGetRegistered}>Get Data</button>
                </div>



                {
                    eventRegLoading ? <div>Loading...</div> : null
                }

                {
                    eventRegError ? <div>{eventRegError.message}</div> : null
                }

                {csvData && csvData.length > 0 ? <CSVLink enclosingCharacter={`"`} data={csvData} filename={eventName + "-registered_users"} className='csv-file'>Download CSV</CSVLink> : null}


                <table className='reg-table'>
                    <tr>
                        <th>Saarang ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>College</th>
                    </tr>
                    {
                        eventRegData?.getRegisteredUsersForEvent.length === 0 ? <tr><td colSpan={5}>No Registrations found</td></tr> : null
                    }
                    {
                        eventRegData?.getRegisteredUsersForEvent.map((user) => {
                            return (
                                <tr>
                                    <td>{user.saarang_id}</td>
                                    <td>{user.username}</td>
                                    <td>{user.email_primary}</td>
                                    <td>{user.mobile_number_primary}</td>
                                    <td>{user.college}</td>
                                </tr>
                            )

                        })
                    }
                </table>
            </div>
        </div>
    )
}

export default GetRegistered