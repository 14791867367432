import React, { useEffect , useState } from 'react';
import { CSVLink } from 'react-csv';
import './sd_sales.scss';
import { useGetTicketingRegsBySlotLazyQuery, useGetTicketingRegsBySlotQuery, useGetTicketingSalesStatsLazyQuery , useGetTicketingEventsQuery } from '../../generated/graphql';


function Sdsales (){


    const [getSalesofSlots,{data:slotssales_data,loading:slotssales_loading,error:slotssales_error}] = useGetTicketingSalesStatsLazyQuery();
    const [getRegdBySlot,{data:regdByslot_data,loading:regdByslot_loading,error:regdByslot_error}] = useGetTicketingRegsBySlotLazyQuery();
    const { data:slots_data, loading:slots_loading, error:slots_error } = useGetTicketingEventsQuery()
    
    
    const[csvData,setcsvData]=useState();
    const [Disp, setDisp] = useState(0);
    const [SlotList, setSlotList] = useState();
    const [selectedSlotId, setSelectedSlotId] = useState(null);
    const [fileName, setfileName] = useState();

    
    const executeGetSalesofSlots = () => {
        getSalesofSlots();

        setfileName("sold_per_slot")
        setDisp(1);
    }


    const handleSlotChange = (e) =>{
        
        // Find the selected option based on the entered value
        const selectedOption = SlotList.find(
            (slot) => (new Date(slot.date).getDate() + "th " + slot.slot) === e.target.value
        );
        // Update the selectedSlotId with the ID of the selected option
        setSelectedSlotId(selectedOption ? selectedOption.id : null);
        
        setfileName(e.target.value)
    };

    const exceuteRegdBySlot =() =>{
        getRegdBySlot({
            variables:{
                'slotId':selectedSlotId,
            }
        });
        setDisp(2);
        
    }



    useEffect(() => {
        if(slotssales_data) {
            setcsvData(slotssales_data.getTicketingSalesStats);
        } 
    }, [slotssales_data]);


    useEffect(() => {
        if(regdByslot_data){
            setcsvData(regdByslot_data.getTicketingRegsBySlot);
        }
    }, [regdByslot_data]);


    useEffect(() => {
        if(slots_data){
            setSlotList([...slots_data.getTicketingEvents]);
        }
    },[slots_data])






    return (
        <div className='sdsales-container'>
            <div className="salesofslots-button" onClick={executeGetSalesofSlots}>Get Total Sales of Each Slot</div>
            <div className="get-slotdata-box">
                <label>Select Slot</label>
                <input list='slots' onChange={handleSlotChange} ></input>
                    <datalist id="slots">
                        {SlotList?.map((slot, index) => {
                            return <option value={(new Date(slot.date).getDate()+"th "+ slot.slot)} id={slot.id} data-value={slot.id} ></option>
                        })}
                    </datalist>
                    <button onClick={exceuteRegdBySlot}>Get Data of Slot</button>
            </div>
            {Disp === 1 ? (slotssales_loading ? <div> ... Loading</div> :null ) :null}   
            {Disp === 1 ? (slotssales_error ? <div className='error'> {slotssales_error.message} </div> :null) :null}
            
            {Disp === 2 ? (regdByslot_loading? <div> ...Loading </div> :null) :null}
            {Disp === 2 ? (regdByslot_error ? <div className='error'> {regdByslot_error.message} </div> :null):null}
            
            {csvData && csvData.length > 0 ? <CSVLink enclosingCharacter={`"`} data={csvData} filename={fileName} className='csv-file'>Download CSV</CSVLink> : null}
            
            <table>
            {Disp === 1 ? (slotssales_data?.getTicketingSalesStats.length !== 0?
                    <tr>
                        <th>Date</th>
                        <th>Slot</th>
                        <th>T.Sold</th>
                    </tr>:null) :null
            }
            {Disp === 1 ? (slotssales_data?.getTicketingSalesStats.map((slot)=>
                (
                    <tr>
                        <td>{(new Date(slot.date).getDate())}th </td>
                        <td>{slot.slot}</td>
                        <td>{slot.quantity_sold}</td>
                    </tr>
                )
            )):null}



            {Disp === 2 ? (regdByslot_data?.getTicketingRegsBySlot.length !== 0?
                    <tr>
                        <th>UserName</th>
                        <th>Mobile</th>
                        <th>Email</th>
                        <th>College</th>
                        <th>No Of Tickets </th>
                    </tr>:null) :null
            }


            {Disp === 2 ? (regdByslot_data?.getTicketingRegsBySlot.map((user)=>
                (
                    <tr>
                        <td>{user.username}</td>
                        <td>{user.mobile_number_primary}</td>
                        <td>{user.email_primary}</td>
                        <td>{user.college}</td>
                        <td>{user.quantity}</td>
                    </tr>
                )
            )):null}

            </table>
            
        </div>
        
    )
}

export default Sdsales;