import React from 'react'
import { Link } from 'react-router-dom';
// import './css.css'
interface Event_CardProps {
    title: String;
    event_img: string;
    id: string;
}
const css = `
.card-container {
    
    text-decoration: none; 
    display: flex;
    background: linear-gradient(150.27deg, rgba(217, 217, 217, 0.15) -8.89%, rgba(217, 217, 217, 0) 123.31%);        
    border-radius: 18px;
    border: 1px solid;
    border-image-source: linear-gradient(157.13deg, rgba(176, 157, 157, 0.6) 1.63%, rgba(176, 157, 157, 0) 32.1%);
    flex-direction: column;
    padding: 5px 15px;
    margin: 15px 20px;
    
}

.card-container:hover {
    background: linear-gradient(150.27deg, #6140D3 -8.89%, #9C85EC 123.31%);
    box-shadow: inset -4px -4px 13px #6848D6;
    border: 1px solid;
    border-image-source: linear-gradient(157.13deg, rgba(176, 157, 157, 0.6) 1.63%, rgba(176, 157, 157, 0) 32.1%);
}

h1 {
    font-family: 'Josefin Sans' ;
    font-family: 'Alegreya Sans SC' !important;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 32px;
    text-transform: lowercase;
    color: #FFFFFF;
}
img {
    border-radius: 10px;
    width: 200px;
    height: 160px;
    margin: auto ;
    padding-bottom: 10px;
}

p {
    font-family: 'Josefin Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #FFFFFF;
}

@media screen and (max-width: 520px) {
    .card-container {
        width: 90px ;
        height: 100px ;
        padding: 5px 5px;
        overflow: hidden;
    }

    img {
        width: 80px ;
        height: auto ;
        margin: auto;
    }

    h1 {
        font-size: 15px !important;
        line-height: 15px;
    }
}

`
const Event_Card: React.FunctionComponent<Event_CardProps> = ({ event_img, title, id }: Event_CardProps) => {
    // console.log("Image:", event_img);
    return (
        <Link className='card-container' to={{ pathname: `/event/${id}` }} state={{ event_img: event_img, title: title }}>
            <style>
                {css}
            </style>
            <h1> {title}</h1>
            <img src={event_img} alt="" ></img>
            {/* <p>{desp}</p> */}

        </Link>
    );
}

export default Event_Card;