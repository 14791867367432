import React, { useState } from 'react'
import { useGetMyEventsQuery, useGetSaarangIdQuery } from '../../generated/graphql';
import './registered_events.css'
const RegisteredEvents = () => {


    const extractDateTime = (dateTime: string | undefined) => {
        if (dateTime) {

            const date = new Date(dateTime);
            let month = date.toUTCString().split(' ')[2];
            if (month === 'Dec') {
                month = 'Jan';
            }

            const day = date.getUTCDate();

            const hours = date.getUTCHours();
            const minutes = date.getUTCMinutes();
            const ampm = hours >= 12 ? 'PM' : 'AM';
            const hour = hours % 12;
            const min = minutes < 10 ? '0' + minutes : minutes;

            return `${month} ${day}, 2024 at ${hour}:${min} ${ampm}`;
        }

    }

    const [saarangID, setSaarangID] = useState<string>("");

    const { data: myEvents, loading, error } = useGetMyEventsQuery({
        variables: {
        },
    });

    const { data: IDdata, loading: IDloading, error: IDerror } = useGetSaarangIdQuery({
    });

    React.useEffect(() => {
        if (IDdata) {
            setSaarangID(IDdata.getMe_query.saarang_id);
        }
    }, [IDdata])


    return (
        <div className="schedule-page">

            <div className="launching_text_s">
                <div className='saarang-id'>ID: {saarangID}</div>
                <div className="schedule-head">REGISTERED EVENTS</div>
                <table id="schedule">
                    {
                        loading ? <tr><td>Loading...</td></tr> : null
                    }

                    {
                        error ? <tr><td>{error.message}</td></tr> : null
                    }

                    {
                        myEvents && myEvents.getMyEvents.length === 0 ? <tr><td>No events registered</td></tr> : null
                    }

                    {
                        myEvents ? <><tr>
                            <th>S.No</th>
                            <th>Title</th>
                            <th>Date</th>
                        </tr>
                            {myEvents.getMyEvents.map((event, index) => {
                                return (
                                    <tr className="event-row">
                                        <td>{index + 1}</td>
                                        <td>{event.title}</td>
                                        <td>{extractDateTime(event.event_date)}</td>
                                        <td><a href={`https://saarang.org/event/${event.id}`} target='_blank' className="register-event">View More</a></td>
                                    </tr>
                                )
                            })}
                        </>
                            : null
                    }

                </table>

            </div>
        </div>
    )
}

export default RegisteredEvents