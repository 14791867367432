import React, { useState, useEffect } from 'react';

import './silentdisco.scss';
import { useGetTicketingEventsQuery } from '../../generated/graphql';
import axios from 'axios';
import useRazorpay from 'react-razorpay';





function SilentDisco() {

    
    const [selectedSlot, setSelectedSlot] = useState(null);
    // const [selectedCapacity, setselectedCapacity] = useState(null);
    const [Persons, setPersons] = useState(0);
    
    const handleSlotSelection = (slotId) => {
        setSelectedSlot(slotId);
        setPersons(0);
        // setselectedCapacity(slotCapacity);
        
    };
    
    
    const { data, loading, error } = useGetTicketingEventsQuery()


    const [Day0, setDay0] = useState([]);
    const [Day1, setDay1] = useState([]);
    const [Day2, setDay2] = useState([]);
    const [Day3, setDay3] = useState([]);
    const [Day4, setDay4] = useState([]);


    useEffect(() => {
        if(data) {
            
        
        
        let dummydata=[...data.getTicketingEvents];
        dummydata.sort((a, b) => new Date(a.date) - new Date(b.date)); 
        console.log(dummydata);
        let cDay0=[];
        let cDay1=[];
        let cDay2=[];
        let cDay3=[];
        let cDay4=[];
        for (var i = 0; i < dummydata.length; i++) {
            const currentItem = dummydata[i];
            
            
            if (new Date(currentItem.date).getDate() === 10) {
                cDay0.push(currentItem);
            } else if (new Date(currentItem.date).getDate() === 11) {
                cDay1.push(currentItem);
            } else if (new Date(currentItem.date).getDate() === 12) {
                cDay2.push(currentItem);
            }else if (new Date(currentItem.date).getDate() === 13) {
                cDay3.push(currentItem);
            }else if (new Date(currentItem.date).getDate() === 14) {
                cDay4.push(currentItem);
            }
        }

        setDay0(cDay0);
        setDay1(cDay1);
        setDay2(cDay2);
        setDay3(cDay3);
        setDay4(cDay4);
        }

    }, [data]);

    
    const  slotlist = (day) => {
        return(
                <ul>
                    {day.map((slot) => ((!slot.is_full)?
                    <li key={slot.id}>
                        <label>
                        <input
                            type="radio"
                            name="selectedSlot"
                            checked={selectedSlot === slot.id }
                            onChange={() => handleSlotSelection(slot.id)}
                        />
                         &nbsp;{slot.slot}
                        </label> 
                    </li>
                        : <div className='slot-filled'>{slot.slot} is filled </div>
                    ))}
                </ul>
        )
        
    }

    const decrementQuantity = () => {
        if (Persons>0) setPersons(Persons-1);
    }

    const incrementQuantity = ()=> {
        if ( selectedSlot != null ){
            setPersons(Persons+1);
            return;
        }
        if ( selectedSlot === null) {
            alert("Please Select A Slot");
            return;
        }
        
    }

    const Razorpay = useRazorpay();

    const handleBookingConfirmation = () => {
        if (selectedSlot === null){
             alert('please choose a slot');
             return;
        }
        if (Persons === 0) {
            alert('please choose no of tickets');
            return;
        }

        try {
            axios({
                url: "https://saarang.org/api2/",
                // url: "http://localhost:8001/graphql",
                method: "post",
                data: {
                    query: `
                    mutation RegisterEvent($quantity: Float!, $eventId: String!) {
                        registerEvent(quantity: $quantity, EventId: $eventId) {
                          amount
                          id
                        }
                      }
                `,
                    variables: {
                        quantity : Persons,
                        eventId : selectedSlot,
                    },
                },
            }).then((result) => {
                if (result.data.errors) {
                    alert(result.data.errors[0].message);
                    return;
                }

                const rp_data = result.data.data.registerEvent;


                const options = {
                    key: "rzp_live_VgVO9uzPsxViN5",
                    // key: "rzp_test_pujGikRKjzH2x7",
                    amount: String(rp_data.amount),
                    currency: "INR",
                    name: "Saarang 2024",
                    description: "Payment Transaction",
                    payment_capture: "true",
                    // image: "https://example.com/your_logo",
                    order_id: rp_data.id,
                    handler: async function (response) {
                        // setPaymentID(res.razorpay_payment_id);
                        // setSignatureID(res.razorpay_signature);
                        // console.log(response);
                        const data = {
                            razorpayPaymentId: response.razorpay_payment_id,
                            razorpayOrderId: response.razorpay_order_id,
                            razorpaySignature: response.razorpay_signature,
                        };

                        const res = await axios({
                            url: "https://saarang.org/api2/",
                            // url: "http://localhost:8001/graphql",
                            method: "post",
                            data: {
                                query: `
                                mutation Verify_signature_te($razorpayOrderId: String!, $razorpayPaymentId: String!, $razorpaySignature: String!) {
                                    verify_signature_te(razorpay_order_id: $razorpayOrderId, razorpay_payment_id: $razorpayPaymentId, razorpay_signature: $razorpaySignature)
                                  }
                    `,
                                variables: {
                                    razorpayOrderId: data.razorpayOrderId,
                                    razorpayPaymentId: data.razorpayPaymentId,
                                    razorpaySignature: data.razorpaySignature,
                                },
                            },
                        });

                        // console.log(res.data.data.verify_signature);
                        if (res.data.data.verify_signature_te == true) {
                            alert(`Purchase successful`);
                        }
                    },
                };
                const rzp1 = new Razorpay(options);
                rzp1.open();
            });
        } catch (err) {
            alert(err);
            console.log(err);
        }
    };
    


    return (
        <div className="disco-container">
            <div className="disco-heading">Silent Disco</div>
            <div className="days">
                <div className="day day-0">
                    <div className='day-heading'>10th Jan</div>
                    <div className='day-rate'> Rs 150 per person</div>
                    {slotlist(Day0)}
                </div>

                <div className="day day-1">
                    <div className='day-heading'>11th Jan</div>
                    <div className='day-rate'> Rs 150 per person</div>
                    {slotlist(Day1)}
                </div>

                <div className="day day-2">
                    <div className='day-heading'>12th Jan</div>
                    <div className='day-rate'> Rs 150 per person</div>
                    {slotlist(Day2)}
                </div>

                <div className="day day-3">
                    <div className='day-heading'>13th Jan</div>
                    <div className='day-rate'> Rs 200 per person</div>
                    {slotlist(Day3)}
                </div>

                <div className="day day-4">
                    <div className='day-heading'>14th Jan</div>
                    <div className='day-rate'> Rs 200 per person</div>
                    {slotlist(Day4)}
                </div>
            </div>

            <div className="number"> No of Tickets:<button onClick={decrementQuantity}>-</button>{Persons}<button onClick={incrementQuantity}>+</button></div>

            <button className='confirm-button' onClick={handleBookingConfirmation}>Confirm Booking</button>
        </div>
      );
}

export default SilentDisco;