import React, { useRef, useState } from 'react';
import { useCreateEventMutation, useGetAllEventsQuery, useGetEventbyIdLazyQuery, useGetEventbyIdQuery, useUpdateEventMutation } from '../../generated/graphql';
import './putEvents.css';

const EditEvent: React.FC = () => {

    const eventInput = useRef<null | HTMLFormElement>(null);
    const eventGet = useRef<null | HTMLInputElement>(null);
    const [eventID, setEventID] = useState<string>('');

    const [eventName, setEventName] = useState<string | undefined>('');


    const [updateEventMutation, { data: upDateEvent, loading: upDateLoading, error: updateError }] = useUpdateEventMutation({

    });



    const { data: eventsData, loading: eventsLoading, error: eventsError } = useGetAllEventsQuery({
        variables: {
        },
    });




    const [getIDdata, { data: eventIDData, loading: eventIDloading, error: eventIDerror }] = useGetEventbyIdLazyQuery({
    });

    React.useEffect(() => {
        if (eventIDData) {
            const form = eventInput.current;
            if (form) {
                form['clubs_name'].value = eventIDData.getEventbyId.event.club_name;
                form['description'].value = eventIDData.getEventbyId.event.description;
                form['events_category'].value = eventIDData.getEventbyId.event.event_category
                // Set date time
                console.log(eventIDData.getEventbyId.event.event_date);
                // YYYY-MM-DDTHH:MM:SSZ to YYYY-MM-DDTHH:MM
                var date = eventIDData.getEventbyId.event.event_date;
                var date = date.substring(0, date.length - 8);
                console.log(date);
                form['date_time'].value = date;

                // form['date_time'].value = '2019-09-06T00:21' ;//'2002-02-19T09:45:45Z' ; // eventIDData.getEventbyId.event.event_date ; 
                // '2002-02-19' ;new Date(eventIDData.getEventbyId.event.event_date);


                form['poster_url'].value = eventIDData.getEventbyId.event.image_url
                form['judge_image_url'].value = eventIDData.getEventbyId.event.judge_image;
                form['judge_name'].value = eventIDData.getEventbyId.event.judge_name
                form['rules'].value = eventIDData.getEventbyId.event.rules;
                form['event_title'].value = eventIDData.getEventbyId.event.title
                form['tagline'].value = eventIDData.getEventbyId.event.tagline;
                form['submission_link'].value = eventIDData.getEventbyId.event.submission_link;
                form['type'].value = eventIDData.getEventbyId.event.type;
                form['price'].value = eventIDData.getEventbyId.event.price;
                form['event_location'].value = eventIDData.getEventbyId.event.event_location;
                let reg_closed = eventIDData.getEventbyId.event.registrations_closed ? 'true' : 'false';
                form['registrations_closed'].value = reg_closed;

            }
        }
    }, [eventIDData])


    const getEventData = () => {

        const eventRef = eventGet.current;
        var event = document.getElementById(eventRef ? eventRef.value : 'DUMMYID');
        getIDdata({
            variables: {
                "eventId": event?.dataset.value ? event?.dataset.value : '1'
            }
        })

        if (event && event.dataset.value) {
            setEventID(event?.dataset.value);
        }
    }



    // console.log(eventsData?.getAllEvents);



    const onSubmit = (e: any) => {
        e.preventDefault();
        const form = eventInput.current;

        if (form) {
            updateEventMutation({
                variables: {
                    "data": {
                        'club_name': form['clubs_name'].value,
                        'description': form['description'].value,
                        'event_category': form['events_category'].value,
                        "event_date": form['date_time'].value,
                        "image_url": form['poster_url'].value,
                        "judge_image": form['judge_image_url'].value,
                        "judge_name": form['judge_name'].value,
                        "rules": form['rules'].value,
                        'title': form['event_title'].value,
                        'tagline': form['tagline'].value,
                        'submission_link': form['submission_link'].value,
                        'type': form['type'].value,
                        'price': parseInt(form['price'].value),
                        "event_location": form['event_location'].value,
                        'registrations_closed': form['registrations_closed'].value == 'true' ? true : false
                    },
                    'eventId': eventID
                }
            })


        }

    };

    return (
        <div className="register-form" >

            {
                eventsLoading ? <div style={{ 'fontWeight': 'bold', 'textAlign': 'center', 'fontSize': '20px', 'marginTop': '100px', 'minHeight': '600px' }} className='loading'>Loading...</div> : null
            }

            <div className='get-edit-event'>
                <label>Enter event name to edit</label>
                <input list='events' onChange={(e) => setEventName(e.target.value)} required ref={eventGet}></input>
                <datalist id="events">
                    {eventsData?.getAllEvents.map((event) => {
                        return <option value={event.title} id={event.title} data-value={event.id} ></option>
                    })}
                </datalist>
                <button onClick={getEventData}>{eventIDData ? "✓" : <>Get Data</>} </button>
            </div>

            {
                eventIDloading ? <div className='loading' style={{ 'backgroundColor': 'rgb(223, 223, 223)', 'color': 'black', 'width': '100%', 'textAlign': 'center' }}> Loading...</div> : null
            }

            {
                eventIDerror ? <div className='error' style={{ 'backgroundColor': 'rgb(223, 223, 223)', 'color': 'red', 'width': '100%', 'textAlign': 'center' }} > Event title not found. Please select from list suggested</div> : null
            }

            <form onSubmit={onSubmit} ref={eventInput} style={{ 'marginTop': '0px' }}>
                <div className='form-box'>
                    <h2>
                        Event
                    </h2>
                    <div className="form-group">
                        <label>Event Category</label>
                        <select name="event_category" id="events_category">
                            <option value="performing">Performing Arts</option>
                            <option value="literary">Literary Arts</option>
                            <option value="creative">Creative Arts</option>
                            <option value="digital">Digital Arts</option>
                            <option value="nova">Nova</option>
                            <option value="noncompetitive">Non Competitive Events</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Club Name</label>
                        <select name="club_name" id="clubs_name">
                            <option value="choreo">Choreo</option>
                            <option value="classicalarts">Classical Arts</option>
                            <option value="drama">Drama</option>
                            <option value="music">Music</option>
                            <option value="comedy">Comedy</option>
                            <option value="quiz">Quiz</option>
                            <option value="oratory">Oratory</option>
                            <option value="wordgames">Word Games</option>
                            <option value="writing">Writing</option>
                            <option value="fashion">Fashion</option>
                            <option value="finearts">Fine Arts</option>
                            <option value="culinary">Culinary</option>
                            <option value="designvfx">Design & VFX</option>
                            <option value="esports">E-Sports</option>
                            <option value="media">Media</option>
                            <option value="informals">Informals</option>
                            <option value="spotlight">Spotlight</option>
                            <option value="timetrek">Time Trek</option>
                            <option value="incredibleindia">Incredible India</option>
                            <option value="multiverse">multiverse</option>
                            <option value="meraki">Meraki</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Title</label>
                        <input type="text" name='event_title' required />
                    </div>
                    <div className="form-group">
                        <label>Tagline</label>
                        <input type="text" name='tagline' required />
                    </div>
                    <div className="form-group">
                        <label>Poster URL</label>
                        <input type="text" name='poster_url' required />
                    </div>
                    <div className="form-group">
                        <label>Description</label>
                        <textarea rows={10} cols={53} name="description" placeholder="Enter..."></textarea>
                        {/* <input style={{ 'height': '200px' }} type="text" name='description' required /> */}
                    </div>
                    <div className="form-group">
                        <label>Rules and Regulations</label>
                        <textarea rows={10} cols={53} name="rules" placeholder="Add full stop after each rule... "></textarea>
                        {/* <input style={{ 'height': '200px' }} type="text" name='rules' required /> */}
                    </div>

                    <div className="form-group">
                        <label>Date and Time</label>
                        <input type="datetime-local" name='date_time' required />
                    </div>
                    <div className="form-group">
                        <label>Submission Form Link</label>
                        <input type="text" name='submission_link' placeholder='Leave blank if does not apply' />
                    </div>
                    <div className="form-group">
                        <label>Judge Name</label>
                        <input type="text" name='judge_name' required />
                    </div>
                    <div className="form-group">
                        <label>Judge Image URL</label>
                        <input type="text" name='judge_image_url' required />
                    </div>
                    <div className="form-group">
                        <label>Type</label>
                        <select name="type" id="type">
                            <option value="event">Event</option>
                            <option value="workshop">Workshop</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Price</label>
                        <input type="number" name='price' />
                    </div>
                    <div className="form-group">
                        <label>Registration Closed</label>
                        <select name="registrations_closed" id="registrations_closed">
                            <option value="true">True</option>
                            <option value="false">False</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Location</label>
                        <input type="text" name='event_location' required />
                    </div>
                    <div className="form-group-submit">
                        {upDateLoading ? <p style={{ 'color': 'black', 'textAlign': 'center' }}>Adding..</p> : <button type="submit" className="btn btn-primary">
                            Update
                        </button>}
                        <p>&nbsp;
                            {updateError !== undefined ? <p style={{ 'color': 'red', 'textAlign': 'center' }}>Error: {updateError.message}</p> : <p style={{ 'color': 'green', 'textAlign': 'center' }}>{upDateEvent?.updateEvent == true ? "Event Updated successfully. Kindly refresh the page" : ""}</p>}
                        </p>

                    </div>
                </div>

            </form>

            {/* <div className='form-box'>
        <h2>Club Pass</h2>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Club Name</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input type="text" />
          </div>
          <div className="form-group-submit">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div> */}


            {/* <div className='form-box'>
        <div>
          <h1>Sponsors</h1>
        </div>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Company Name</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Image URL</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <h1>

            </h1>
          </div>
        </form>
      </div> */}
        </div >
    );
};

export default EditEvent;
