import React, { useState, useEffect , useRef } from 'react';
import { motion } from 'framer-motion';
import './adventurezone.scss';



function Adventure() {

    const [positionIndexes, setPositionIndexes] = useState([0, 1, 2, 3, 4]);
    const [isInView, setIsInView] = useState(false);
    const carouselRef = useRef(null); // Ref for the carousel element
  
    const lap=(window.innerWidth > 738);
  
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsInView(entry.isIntersecting);
        },
        { threshold: 0.} // Adjust this value based on how much of the element should be in view to trigger autoplay
      );
  
      if (carouselRef.current) {
        observer.observe(carouselRef.current);
      }
  
      return () => {
        if (carouselRef.current) {
          observer.unobserve(carouselRef.current);
        }
      };
    }, [carouselRef]);
  
  
    // useEffect(() => {
    //   if (isInView) {
    //     const interval = setInterval(handleBack, 2000); // Autoplay interval
    //     return () => clearInterval(interval);
    //   }
    // }, [isInView]);
  
  
    // const handleNext = () => {
    //   setPositionIndexes((prevIndexes) => {
    //     const updatedIndexes = prevIndexes.map(
    //       (prevIndex) => (prevIndex + 1) % 5
    //     );
    //     return updatedIndexes;
    //   });
    // };
  
  
    // const handleBack = () => {
    //   setPositionIndexes((prevIndexes) => {
    //     const updatedIndexes = prevIndexes.map(
    //       (prevIndex) => (prevIndex + 4) % 5
    //     );
  
    //     return updatedIndexes;
    //   });
    // };
  
    // const images = ['https://iili.io/JAci38B.jpg',//Giant Wheel 
    //                 'https://iili.io/JAci2aV.jpg', //Columbus
    //                 "https://iili.io/JuXTPbp.jpg", //Silent Disco
    //                 "https://iili.io/JuXT45v.jpg",//Mechanical Bull
    //                 "https://iili.io/JuXTrdJ.jpg", //Body Zorbing
    //                ];

    const adzone= [
                    {
                        title: "Mechanical Bull",
                        desc: "Saddle up for a wild ride on our mechanical bull! Feel the adrenaline as you try to conquer the bucking beast - it's a showdown of skill and stamina.",
                        venue: "KV Ground",
                        img:"https://drive.google.com/uc?id=1JeNvSN9aYhXPF2jmFXWTEuJpy2NL2FZO",
                        ticket_details:" Tickets are sold in counter near KV Ground"
                    },
                    {
                      title: "Zorb Football",
                      desc: "Step inside your own giant, bouncy bubble and roll with laughter! Body Zorbing is an exhilarating experience of bouncing, rolling, and colliding - all the fun of being a human bumper ball! ",
                      venue: "KV Ground",
                      img:"https://iili.io/JuXTrdJ.jpg",

                      ticket_details:" Tickets are sold in counter near KV Ground"
                    },
                    {
                      title: "Silent Disco",
                      desc: "Groove to the beat like never before at our Silent Disco! Slip on your headphones, and dance without missing a beat. It's a silent party, but the energy is anything but quiet!",
                      venue: "HSB 133",
                      img:"https://iili.io/JuXTPbp.jpg",
                      ticket_details:<div className='silent-disco-button'><a href='/silentdisco'>Book Your Slot</a></div>
                    },
                    {
                      title: "Cross Wheel",
                      desc: "",
                      venue: "KV Ground",
                      img:'https://drive.google.com/uc?id=1dzB0YMt6tEzyGX32yjOyLXY7dn_NYejz',
                      ticket_details:" Tickets are sold in counter near KV Ground"
                    },
                    {
                      title: "Columbus",
                      desc: "",
                      venue: "KV Ground",
                      img:'https://iili.io/JAci2aV.jpg',
                      ticket_details:" Tickets are sold in counter near KV Ground"
                    },
                 ];
  
    // const names = ["Giant Wheel", "Columbus", "Silent Disco", "Mechanical Bull", "Body Zorbing"];
    // const positions = ["third", "fourth", "fifth", "first", "second"];
  
  
    // const imageVariants = {
    //     first: { x: "15%",y:"25px" ,scale: 1, zIndex: 5 ,rotate:5},
    //     second: { x: "8%",y:"10px", scale: 1, zIndex: 4 , rotate:-1},
    //     third: { x: "0%",y:"5px", scale: 1, zIndex: 3,rotate:-6, },
    //     fourth: { x: "-5%",y:"0px", scale: 1, zIndex: 2 ,rotate:-11, },
    //     fifth: { x: "-10%",y:"-5px", scale: 1, zIndex: 1 ,rotate:-16, },
    // };


    return ( 
        <div className="adventure-container"  ref={carouselRef}>
            <div className="heading">Adrenaline Zone</div>



        {/* <motion.div className="adv-carousel">
          {images.map((image, index) => (
            <motion.div
              key={index}
              src={image}
              alt={image}
              className="carousel-img"
              initial="third"
              animate={positions[positionIndexes[index]]}
              variants={imageVariants}
              transition={{ duration: 0.5 }}
              style={{ width:(window.innerWidth > 738)? "30%" : "60%", position: "absolute" }}
            >
                <motion.img src={image} alt={image}/>
                <motion.span style={{display:'block'}}> {names[index]}</motion.span>
            </motion.div>
          ))}
        </motion.div> */}
            <div className="cards-container">
                {adzone.map((article, i) => (
                    <motion.div
                    key={i}
                    className='main-card'
                    initial={{
                      opacity: 0,
                      // translateX: -100,
                      translateY: 100,
                      rotateY: -180,
                    }}
                    animate={{
                      opacity: isInView ? 1 : 0,
                      // translateX: isInView ? 0 : -100,
                      translateY: isInView ? 0 : 100,
                      rotateY: isInView ? 0 : -180,
                      transition: { delay: i * 0.4 },
                    }}
                    transition={{ type: 'spring', bounce: 0.1 }}
                  >
                    <img
                      src={article.img}
                      className='card-image'
                      
                    />
              
                    <div className="card-heading">
                      {article.title}
                    </div>
              
                    <div className='card-content' >
                      <div className="card-desc">{article.desc}</div>
                      <div className="card-venue">Venue: {article.venue}</div>
                      <div className="card-booking">{article.ticket_details}</div>
                    </div>
                  </motion.div> 
                ))}

            </div>
        </div>
     );
}

export default Adventure;