import * as React from "react";
import { useState, useEffect, useContext } from "react";
import "./events.css";
import Event_Card from "../../components/event_card";
import axios from "axios";
import { useParams } from "react-router-dom";
import useRazorpay from "react-razorpay";
import AuthContext from "../../contexts/AuthContext";
import { FaTicketAlt } from "react-icons/fa";
import { useGetMe_QueryQuery } from "../../generated/graphql";
import Combos from "../../components/combos/combos";
import { string } from "yup";

axios.defaults.withCredentials = true

interface RazorpayOptions {
  key: string;
  amount: string;
  currency: string;
  name: string;
  description: string;
  image?: string;
  payment_capture?: string;
  order_id: string;
  notes : {
    type : string;
  }
  handler: (res: any) => void;
  //   modal: {
  //     ondismiss: () => void;
  //   };
}

interface RazorpayResponse {
  razorpay_payment_id: string;
  razorpay_order_id: string;
  razorpay_signature: string;
}

interface event {
  title: string;
  image_url: string;
  id: string;
  type: string;
}

interface resp {
  club_name: string;
  club_pass_price: number;
  has_pass: boolean;
  events: event[];

}
const Events: React.FunctionComponent = () => {
  const event_category = useParams().id;
  const Razorpay = useRazorpay();
  const [admin, setAdmin] = useState(false);
  const [username, setUserName] = useState<String>("");
  const [events, setEvents] = React.useState<resp[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const authContext = useContext(AuthContext);



  const [Combodisp, setCombodisp] = useState(false); // To display combos and to confirm which combos to display
  const [Clubcombo,setClub]=useState<String>("");
  



  useEffect(() => {
    if (authContext) {
      if (authContext.state) {
        if (authContext.state.user) {
          setUserName(authContext.state.user.username);
          // console.log(authContext.state.user);
          //admins have first index as 2 instead of zeroth index
          if (authContext.state.user.positions[2] === '1') {
            setAdmin(true);
          } else {
            setAdmin(false);
          }
        }
      }
    }
  }, [authContext]);
  React.useEffect(() => {
    try {
      axios({
        // url: process.env.apiUrl || "http://localhost:8001/graphql",
        url: process.env.apiUrl || "http://localhost:8000/graphql", //Chanmged to 8000 on 16th April, 2024, 0:33AM
        // url: "https://saarang.org/api2/",
        method: "post",
        data: {
          query: `
          query GetEvents($eventCategory: String!) {
            getEvents(EventCategory: $eventCategory) {
              club_name
              club_pass_price
              has_pass
              events {
                id
                title
                image_url
                type
              }
            }
          }
                `,
          variables: {
            eventCategory: event_category,
          },
        },
      }).then((result) => {
        // console.log(result.data);
        setEvents(result.data.data.getEvents);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handlePayment = React.useCallback(
    (name: string) => {
      try {
        axios({
          url: "https://saarang.org/api2/",
          // url: "http://localhost:8001/graphql",
          method: "post",
          data: {
            query: `
                mutation BuyClubPass($name: String!) {
                    buyClubPass(name: $name) {
                      id
                      amount
                    }
                  }
                `,
            variables: {
              name,
            },
          },
        }).then((result) => {
          // console.log(result.data);
          // if(result.data.errors.length > 0){
          //   alert(result.data.errors[0].message);
          //   return;
          // }

          if (result.data.errors && result.data.errors[0].message) {
            alert(result.data.errors[0].message);
            return;
          }


          const rp_data = result.data.data.buyClubPass;

          const options: RazorpayOptions = {
            key: "rzp_live_VgVO9uzPsxViN5",
            // key: "rzp_test_wU5yfvuVKhaCWD",
            amount: String(rp_data.amount),
            currency: "INR",
            name: "Saarang 2023",
            description: "Payment Transaction",
            payment_capture: "true",
            notes : {
              type : "club_pass"
            },
            // image: "https://example.com/your_logo",
            order_id: rp_data.id,
            handler: async function (response: RazorpayResponse) {
              // setPaymentID(res.razorpay_payment_id);
              // setSignatureID(res.razorpay_signature);
              // console.log(response);
              setIsLoading(true);
              const data = {
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
              };

              const res = await axios({
                url: "https://saarang.org/api2/",
                // url: "http://localhost:8001/graphql",
                method: "post",
                data: {
                  query: `
                    mutation Verify_signature($razorpayOrderId: String!, $razorpayPaymentId: String!, $razorpaySignature: String!) {
                        verify_signature(razorpay_order_id: $razorpayOrderId, razorpay_payment_id: $razorpayPaymentId, razorpay_signature: $razorpaySignature)
                      }
                    `,
                  variables: {
                    razorpayOrderId: data.razorpayOrderId,
                    razorpayPaymentId: data.razorpayPaymentId,
                    razorpaySignature: data.razorpaySignature,
                  },
                },
              });
              setIsLoading(false);
              // console.log(res.data.data.verify_signature);
              if (res.data.data.verify_signature == true) {
                alert("Club Pass Purchase successful");
              }
            },
            //To deal with user clicking on close button on razorpay dialog box => contexts, states to be reset
            //   modal: {
            //     ondismiss: function () {
            //       window.location.reload();
            //     },
            //   },
          };

          const rzp1 = new Razorpay(options);
          rzp1.open();
        });
      } catch (err) {
        console.log(err);
      }
    },
    [Razorpay]
  );

  // const { data: meData, loading: meLoading, error: meError } = useGetMe_QueryQuery({
  //   variables: {
  //   },
  // });

  React.useEffect(() => {
    // console.log("Me:", meData);
  });

  const showClubEvents = (e: any) => {

    console.log("ID", e.target.id);
    e.preventDefault();
    var element = document.getElementById(e.target.id + "events");
    if (element)
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  if (isLoading) return <div style={{
    height: "100vh",
    width: "100vw",
    padding: "10%",
  }}><h1 style={{ margin: "auto" }}> Please wait while payment is processing ...</h1></div>


  return (
    <div className="events-page">

      <div className="club_names">
        {events.length > 0 ? (
          events.map((e) => (
            <div className="club_title" id={e.club_name} onClick={showClubEvents}>{e.club_name}</div>))) : (null)}
      </div>
      <div className="pass-price" onClick={()=>setCombodisp(!Combodisp)}> Get Combo Passes </div>
      {Combodisp?<Combos setCombodisp={setCombodisp} />:<></>}

      {events.length > 0 ? (
        events.map((e) => {

          let has_events = false;
          let has_workshops = false;

          e.events.map((event) => {
            if (event.type == "event") has_events = true;
            else has_workshops = true;
          })

          return (
            <div>

              {has_events ? <>
                <div className="title" id={e.club_name + "events"}>{e.club_name} Events</div>
                {e.has_pass ? e.club_name == "meraki" || e.club_name=='timetrek'||e.club_name=='incredibleindia'|| e.club_name=='multiverse' ? null : <p className="club_pass_purchase">Club Pass purchased ✔ </p> : (
                 <> <div
                    className="pass-price"
                    onClick={() => {
                      handlePayment(e.club_name);
                    }}
                  >
                    Get Club Pass Rs {e.club_pass_price}/- <i className="arrow button-right"></i>
                  </div>
                 
                  
                </>
                )}
                <div className="events-box">
                  {e.events.map((event) => {
                    if (event.type == "event") return (
                      <Event_Card
                        id={event.id}
                        event_img={event.image_url}
                        title={event.title}
                      ></Event_Card>
                    )
                  })}
                </div>
              </> : null}

              {has_workshops ? <><div className="title" id={e.club_name + "events"}>{e.club_name} Workshops</div>

                <div className="events-box">
                  {e.events.map((event) => {
                    if (event.type == "workshop") return (
                      <Event_Card
                        id={event.id}
                        event_img={event.image_url}
                        title={event.title}
                      ></Event_Card>
                    )
                  })}
                </div></> : null}


            </div>
          )
        })
      ) : (
        <div className="no_events">No Events Found</div>
      )}
    </div>
  );
};

export default Events;
