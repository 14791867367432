import React, { useState, useEffect, useRef } from "react";
import "./loadingvid.css";
import * as constants from "../../constant";
import { useNavigate } from "react-router-dom";

const Loading = () => {
  const navigate = useNavigate();

  const [Visible, setVisible] = useState(false);
  const vidRef = useRef(null);

  const handleVideoEnd = () => {
    setVisible(false);

    navigate("/home");
  };

  useEffect(() => {
    setTimeout(() => {
      setVisible(true);
    }, 1000);
  }, []);

  const [Muted, setMuted] = useState(true);

  const hanndleMuteButton = () => {
    setMuted(!Muted);
  };
  return (
    <div className="video-container">
      <div className="mute-button" onClick={hanndleMuteButton}>
        <img src={Muted ? constants.MUTE_ICON : constants.VOL_ICON} />
      </div>
      <video
        ref={vidRef}
        onEnded={handleVideoEnd}
        className={Visible ? "active" : "inactive"}
        autoPlay
        muted={Muted}
        width={"100%"}
        playsInline
        onLoadedMetadata={() => {
          vidRef.current.playbackRate = 2;
        }}
      >
        <source
          src="https://d1kbtnj9hqaggx.cloudfront.net/animated_part_1.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
      <div className="skip-button" onClick={handleVideoEnd}>
        <img src={constants.SKIP_ICON} />
      </div>
    </div>
  );
};

export default Loading;
