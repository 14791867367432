import './tbd.scss';



function Tbd() {
    return ( 
        <div className="tbd">
            <span>Coming Soon .....</span>
        </div>
        );
}

export default Tbd;