import React from 'react';
import './homepage.scss';
import { motion } from 'framer-motion';
import MainPage from './main-page/main-page';
import About from './about-page/about';
import Proshows from './proshow-page/proshow';
import * as constants from '../../constant';
import Carousel from '../../components/carousel/carousel';
import EventCategory from './event_category/event_category';
import WorldFest from './worldfest/WorldFest';
import Spotlight from './spotlight/spotlight';
import Adventure from './adventurezone/adventurezone';


function Homepage() {

    

    const images =[
        "https://iili.io/H78HrB4.png",
        "https://iili.io/H78JEaS.png",
        "https://iili.io/H78Jrwg.png",
      ];

    return ( 
        <motion.div className='home-container'
        initial={{opacity:0}}
        animate={{opacity:1}}
        transition={{duration:2}}>
            <section id='Home'>
                <MainPage/>
            </section>
            <section id='About'>
                <About/>
            </section>
            <section id='Proshows'>
                <Proshows/>
            </section>   
            <section id='Events'>
                <EventCategory/>
            </section>
            <section id="Adzone">
                <Adventure/>
            </section>
            <section id='WorldFest'>
                <WorldFest/>
            </section>
            <section id='Spotlight'>
                <Spotlight/>
            </section>   
            

        </motion.div>
     );
}

export default Homepage;