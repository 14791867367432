import React, { useState, useEffect } from 'react';
import './bmslinks.scss';



function BMSlinks({setBMSdisp}) {
    return ( 
        <div className="combos-container" >
            <button className='close-button' onClick={()=>{setBMSdisp(false)}}> X </button>

        
        <div className="combo">
            <div className="combo-heading">Pop Night</div>
            <div className="combo-clubs">14th Jan , Sunday</div>
            <button className='combo-button' ><a href="https://in.bookmyshow.com/events/saarang-popular-night/ET00380491" target='_blank'>BUY</a>  </button>
        </div>
        
        
        <div className="combo">
            <div className="combo-heading">EDM Night</div>
            <div className="combo-clubs">12th Jan , Friday</div>
            <button className='combo-button' ><a href="https://in.bookmyshow.com/events/saarang-edm-night/ET00380490" target='_blank'>BUY</a>  </button>

        </div>

        <div className="combo">
            <div className="combo-heading">Rock Night</div>
            <div className="combo-clubs">13th Jan , Saturday</div>
            <button className='combo-button' ><a href="https://in.bookmyshow.com/events/saarang-rock-night/ET00380492" target='_blank'>BUY</a>  </button>
        </div> 
        
        
       
        <div className="combo">
            <div className="combo-heading">Choreo Night</div>
            <div className="combo-clubs">11th Jan , Thursday</div>
            <button className='combo-button' ><a href="https://in.bookmyshow.com/events/saarang-choreo-night/ET00380505" target='_blank'>BUY</a>  </button>
        </div>

        </div>

     );
}

export default BMSlinks;