import React from "react";
import { useParams } from "react-router-dom";
import { useVerifyEmailMutation } from "../../generated/graphql";
import AuthContext from "../../contexts/AuthContext";
import { textSpanContainsPosition } from "typescript";

interface TempUserContext {
  username : string;
  user_id : string;
  positions: string
}

interface VerifyEmailProps {
    //tasks: task[] | null;
    //submissions: submission[];
  }

const VerifyEmail: React.FunctionComponent<VerifyEmailProps> = ({
  }: VerifyEmailProps) => {

  const authContext = React.useContext(AuthContext);

  const { id1, id2 } = useParams<{ id1: string, id2: string }>();
  // const [verifyEmailMutation, { data,loading,error }] =
  //   useVerifyEmailMutation({});
  const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation();

  const [tempUser, setTempUser] = React.useState<TempUserContext | null>(null);

  React.useEffect(() => {
    // console.log("Calling verify Email mutation");
    verifyEmailMutation({
      variables: {
        emailBase64:id1!,// value for 'emailBase64'
        loginHash: id2!,// value for 'loginHash'
        },
    });
  }, [])


  React.useEffect(() => {
    if(data){
        if(data.check_reg){
            setTempUser(data.check_reg);
        }
    }
  }, [data]);

  React.useEffect(() => {
    if(tempUser){
        if(authContext){
            authContext.signUp(tempUser);   
        }
      window.location.href = "/home";
    }
  }, [tempUser])

  if (error) {
    return <div className="err">{JSON.stringify(error)} </div>;
  }
  if(loading) {
    return <div className="verify">{loading}</div>;
  }

  return <div className="verify">This is the verification email page</div>;
};

export default VerifyEmail;