import React from "react";
import './rules.css';


function Rules(){

    return (
        <div className="Rules-container">
            <div className='Rule-heading'><h1> OAT Rules and Regulations</h1></div>
            <div className="Rules-list">
                <ol>
                    <li>Gates of entry</li>
                        <ol style={{listStyle:'lower-alpha'}}>
                            <li> Entry of IIT Faculty/Staff members and their families is through GARDEN gate.</li>
                                <ol style={{listStyle:'lower-roman'}}>
                                    <li>A separate queue will be maintained for the Faculty/Staff members and their families.</li>
                                    <li>Kindly carry the Institute ID cards of members.</li>
                                </ol> 
                            <li> Entry of IIT students (Institute ID card holders) is through STUDENT gate. A separate queue will be maintained for IIT students. Students must also carry their Student ID Cards.</li>
                            <li> Visitors are allowed through Family and Main Gate inside OAT.</li>
                        </ol>
                
                    <li>Tickets are non-transferable. </li>
                    <li>Re-entry into the venue of ticketed shows is strictly prohibited with exceptions in case of a medical ernergency.</li>
                    <li>Any attempt to enter into a prohibited area in the venue will lead to eviction from the venue. Also attempts by gallery patrons to enter into the bow! area will also attract eviction from the venue.</li>
                    <li>Kindly go through all the instructions given at the back of the ticket.</li>
                    <li>Always carry a valid photo identity proof and must be able to provide it if asked for.</li>
                    <li>Patrons with tickets to Bowl and Fan pass will be provided with colored wristbands and no replacement will be provided in case of loss or tarnpering of the wristband.</li>
                    <li>Patrons must always carry a valid photo identity proof and must be able to provide it if asked Tor, failing to do so may result in denial of entry in the venue.Patrons with tickets will be provided with wrist bands.</li>
                    <li>Patrons with tickets will be provided with wristbands.</li>
                    <li>Organizers reserve the right to send the patrons out of OAT if found without appropriate wristbands.</li>
                    <li>Right to admission is reserved by the Saarang organizing team.</li>
                    <li>Tickets with tampered hologram and the tickets void of hologram or the official stamp and signature will be considered inadmissible.</li>
                    <li>Requests for refund will not be entertained at any time or in any circumstances.</li>
                    <li>No cash payments are acceptable. Payments are to be made through POS or UPI.</li>
                    <li>Little children, senior citizens, cardiac patients are not encouraged to attend the shows as the volume level used may be uncomfortable for them  .</li>
                    <li>Eatables and water bottles are NOT allowed inside.</li>
                    <li>No bags are allowed inside</li>
                    <li>Cameras will not be allowed</li>
                    <li> Helmets, cosmetics, combs,headphones, earphones, key chains*, nail cutters,and any type of sharp objects or any item intended for offensive or defensive use are not allowed.</li>
                        <ol style={{listStyle:'lower-alpha'}}>
                            <li>*Key chains are optionally allowed provided there are no sharp obtrusions. The organizers of Saarang hold the right to declare so.</li>
                        </ol>
                    <li> These following items are strictly not allowed into the venue</li>
                        <ol style={{listStyle:'lower-alpha'}}>
                            <li> Any kind of photographic equipment (except mobile phones). </li>
                            <li> Bags, helmets, cosmetics, inflammable objects, arms and ammunition, cigarettes, power banks, selfie-sticks, headphones and other small sharp objects such as pens, chains, rings, combs etc. </li>   
                            <li> Any kind of food item including water bottles.</li>
                        </ol>
                    <li> Smoking inside the campus is prohibited. Cigarettes, alcoholic material, narcotics and medicines are not allowed inside the OAT.</li>
                    <li> The discretion of the organizing team will be final in deciding if the person is under the influence of alcohal.</li>
                    <li> Bags, helmets, cosmetics, inflammable objects, arrms and ammunition, cigarettes, power banks, selfie sticks, and other small sharp objects such as chains, rings, combs, pens, etc., will not be allowed into the venue. Any item, if viewed as a threat to the safety of the audience and artists, will not be allowed into the venue. The discretion of the organizing team will be final.</li>
                    <li> Drinking water will be provided free of cost and Food & Beverage stalls will be available inside the venue.</li>
                    <li> Any kind of misbehavior or disturbance will result in strict disciplinary action including fines and expulsion from the venue's premises.</li>
                    <li> Anyone found in an ineliated state is strictly not allowed inside OAT.</li>
                    <li> Frisking is done for security reasons. Kindly cooperate.</li>
                    <li> Organizers are not responsible for loss of your personal belongings.</li>
                    <li> Security personnel reserve the right to refuse your entry (Right of admission reserved) or refuse your presence inside the OAT, at any point of time if they deem it unsafe.</li>
                    <li> Organization helds no responsibility for the loss or theft of any personal property. The audience is advised to refrain from bringing valuables such as jewellery, laptops, etc. to the venue.</li>
                    {/* <li> In case of emergency contact the SAARANG control room (functioning 24/7) located near Family Gate, outside OAT.</li>
                        <ol style={{listStyle:'lower-alpha'}}>
                            <li> Duty room: Contact No.-044 2257-862</li>
                            <li> Control Room: Contact No.-044-2257-9999/828</li>
                            <li> Ambulances stationed near the Emergency Gate are available for any health-related emergency</li>
                        </ol> */}
                    <li> Kindly cooperate with the Security and Safety team of Saarang'24, and enjoy the shows. </li>      
                </ol>
            </div>
            {/* <div className='Rule-heading'><h1> Covid Rules and Regulations</h1></div>
            <div className="Rules-list">
                <ul>
                    <li>Owing to the recent conditions surrounding the COVID - 19 pandemic, as a precondition to gaining access to the venue (events and theatres) you are required to be fully vaccinated and may be required to display your COVID -19 vaccination certificate at the venue as per the various norms /regulations prevailing in the said State.</li>
                    <li>The venue provider and governing authorities reserve the right to exclude any user from the venue if there are sufficient grounds to believe so for failure to abide by the protocols.</li>
                    <li>Exit without protest or refund if asked to.</li>
                    <li>The audience is required to check the COVID restrictions as applicable in their state of residence.</li>
                    <li>Use of masks is mandatory at all times.</li>
                    <li>Saarang organizers reserve the right to change/modify the terms and conditions.</li>
                    <li>Sanitizers will be available at the event but it is recommended to bring your own; Self-sanitization is required.</li>
                    <li>The organizers of Saarang are not responsible with regards to any injury or complications due to COVID - 19 occrued as a result of your participation.</li>
                    <li>The above guidelines are mandatory to follow. </li>
                </ul>
            </div> */}

            <div className='Rule-heading'><h1> Events Rules and Regulations</h1></div>
            <div className="Rules-list">
                <ol>
                    
                
                    <li>When you buy any events/club pass, you agree to these terms and conditions. </li>
                    <li>Events/club passes are non - refundable and non transferable.</li>
                    <li>The decisions made by the Event Organizing head/ Club convenor would be final.</li>
                    <li>The Saarang Events Organizing team reserves the right to cancel any event.</li>
                    <li>If there are less than 4 participants in an event, the event might be canceled. The decision lies with the organizing team. </li>
                    <li>In case any event is canceled, there would be no prize money given</li>

                </ol>
            </div>

            
        
        
        </div>
    )
}

export default Rules;

