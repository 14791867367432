import React from 'react'
import { useGetClubPassSalesLazyQuery } from '../../generated/graphql';
import './clubsales.css'
import { CSVLink } from 'react-csv';

const ClubPassSales = () => {

    const [club, setClub] = React.useState<string>('allevents');
    const [csvData, setCsvData] = React.useState<any>(null);

    // Lazy query
    const [getClubPassSales, { data, loading, error }] = useGetClubPassSalesLazyQuery({
        fetchPolicy: 'network-only',
    });

    const handleClubPassSales = () => {

        getClubPassSales({
            variables: {
                'clubName': club
            },
        });
    }

    React.useEffect(() => {
        if (data) {
            setCsvData(data.getClubPassSales);
        }
    }, [data])


    return (
        <div className='register-form'>
            <div className='club-sales'>
                <div className='club-dropdown'>
                    <label>Club Name</label>
                    <select name="club_name" id="clubs_name" onChange={(e) => { setClub(e.target.value) }}>
                        <option value="allevents">All events</option>
                        <option value="choreo">Choreo</option>
                        <option value="classicalarts">Classical Arts</option>
                        <option value="drama">Drama</option>
                        <option value="music">Music</option>
                        <option value="comedy">Comedy</option>
                        <option value="quiz">Quiz</option>
                        <option value="oratory">Oratory</option>
                        <option value="wordgames">Word Games</option>
                        <option value="writing">Writing</option>
                        <option value="fashion">Fashion</option>
                        <option value="finearts">Fine Arts</option>
                        <option value="culinary">Culinary</option>
                        <option value="designvfx">Design & VFX</option>
                        <option value="esports">E-Sports</option>
                        <option value="media">Media</option>
                        <option value="informals">Informals</option>
                        <option value="spotlight">Spotlight</option>
                        <option value="timetrek">timetrek</option>
                        <option value="incredibleindia">incredibleindia</option>
                        <option value="multiverse">multiverse</option>
                        
                    </select>
                    <button onClick={() => { handleClubPassSales() }}>Get Clubpass Sales</button>

                </div>
                <div className='csv-box'>
                    {
                        loading ? <div>Loading...</div> : null
                    }
                    {
                        error ? <div style={{ 'color': 'red' }}>{error.message}</div> : null
                    }
                    {
                        csvData && csvData.length > 0 ? <CSVLink data={csvData} filename={`${club}_clubpass_sales.csv`} className='csv-download'>Download CSV</CSVLink> : null
                    }
                </div>

                <table className='reg-table sales-table'>
                    <tr>
                        <th>Saarang ID</th>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Mobile Number</th>
                        <th>College</th>
                    </tr>
                    {
                        csvData && csvData.length === 0 ? <tr><td colSpan={5}>Zero Sales found</td></tr> : null
                    }
                    {
                        csvData && csvData.map((user: any) => {
                            return (
                                <tr>
                                    <td>{user.saarang_id}</td>
                                    <td>{user.username}</td>
                                    <td>{user.email_primary}</td>
                                    <td>{user.mobile_number_primary}</td>
                                    <td>{user.college}</td>
                                </tr>
                            )

                        })
                    }
                </table>


            </div>
        </div>
    )
}

export default ClubPassSales;