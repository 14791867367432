import axios from "axios";
import React from "react";
import { IconBoxRow4, IconBoxRow3, IconBoxRow2, IconBox } from "../../components/iconbox";
import { Sponsor } from "../../generated/graphql";
import "./sponsors.scss";

axios.defaults.withCredentials = true

{/*interface sponsor {
    name: string;
    image_url: string;
}*/}

const Sponsors = () => {

    {/*const [sponsors, setSponsors] = React.useState<sponsor[]>([]);*/ }

    const [isMobile, setIsMobile] = React.useState(false);
    const [width, setWidth] = React.useState(window.innerWidth);
    React.useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
        };
        window.addEventListener("resize", handleResize);
        if (width < 960) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
        return () => {
            window.removeEventListener("resize", handleResize);
        }
    }, [width]);

    {/*React.useEffect(() => {
        try {
            axios({
                url: "https://saarang.org/api2/",
                // url: "http://localhost:8001/graphql",
                method: "post",
                data: {
                    query: `
                    query GetSponsors {
                        getSponsors {
                            name
                            image_url
                        }
                    }
                    `,
                }
            }).then((result) => {
                // console.log(result.data);
                setSponsors(result.data.data.getSponsors);
            });
        }
        catch (err) {
            console.log(err);
        }
    }, []);*/}

    const sponsors = [
      {
         name:"Coke",
         tag:"Official Beverage Partner",
         site_url:"https://cokestudio.coke2home.com/tamil",
         logo_url:"https://iili.io/J7bHFvS.webp"
      },
      {
         name:"ACT",
         tag:"Powered By Sponsor",
         site_url:"https://www.actcorp.in/",
         logo_url:"https://iili.io/J7bHHGf.webp"
      },
      {
         name:"SBI",
         tag:"Banking Partner",
         site_url:"https://sbi.co.in/",
         logo_url:"https://iili.io/JY5150b.webp"
      },
      {
         name:"Filli",
         tag:"Brewing Partner",
         site_url:"https://fillicafe.com/",
         logo_url:"https://iili.io/J7bHJ44.webp"
      },
      {
         name:"Wynk Music",
         tag:"Digital Music Sponsor",
         site_url:"https://studio.wynk.in/",
         logo_url:"https://iili.io/J7bH3a2.webp"
      },
      {
         name:"PVR",
         tag:"Multiplex Partner",
         site_url:"https://www.pvrcinemas.com/",
         logo_url:"https://iili.io/J7b9pQs.webp"
      },
      {
         name:"Times of India",
         tag:"Knowledge Partner",
         site_url:"https://timesofindia.indiatimes.com/",
         logo_url:"https://iili.io/J7bH9CG.webp"
      },
      {
         name:"Arun Icecreams ",
         tag:"Treat Time Partner",
         site_url:"https://arunicecreams.in/",
         logo_url:"https://iili.io/J7bHqu9.webp"
      },
      {
         name:"Canara Bank ",
         tag:"Banking support sponsor",
         site_url:"https://online.canarabank.in/",
         logo_url:"https://iili.io/J7bH23l.webp"
      },
      {
         name:"ACR",
         tag:"Alumni & Corporate Relations",
         site_url:"https://acr.iitm.ac.in/",
         logo_url:"https://iili.io/Jakwl4V.webp"
      },
      {
         name:"Brownie Heaven India ",
         tag:"Brownie Partner",
         site_url:"https://brownieheavenindia.com/",
         logo_url:"https://iili.io/J7bc3lf.webp"
      },
      {
         name:"G Square Housing",
         tag:"Housing Partner",
         site_url:"https://www.gsquarehousing.com/",
         logo_url:"####"
      },
      {
         name:"ICICI Bank",
         tag:"Bank Sponsor",
         site_url:"https://www.icicibank.com/",
         logo_url:"https://iili.io/J7bcJPs.webp"
      },
      {
         name:"Instax",
         tag:"Moments Partner",
         site_url:"https://instax.com/",
         logo_url:"https://iili.io/J7bc9oX.webp"
      },
      {
         name:"Levista Coffee",
         tag:"Premium Coffee Partner",
         site_url:"https://levista.in/",
         logo_url:"https://iili.io/J7baptt.webp"
      },
      {
         name:"Mad Over Donuts",
         tag:"Pastry Partner",
         site_url:"https://madoverdonuts.com/",
         logo_url:"https://iili.io/J7bcHVn.webp"
      },
      {
         name:"Medlock",
         tag:"Healthcare Partner",
         site_url:"https://www.medlock.co.in/",
         logo_url:"https://iili.io/JakwcEQ.webp"
      },
      {
         name:"NextIAS",
         tag:"Educational Sponsor",
         site_url:"https://www.nextias.com/",
         logo_url:"https://iili.io/J7bamNI.webp"
      },
      {
         name:"ONGC",
         tag:"Energy Sustainability Sponsor",
         site_url:"https://ongcindia.com/",
         logo_url:"https://iili.io/J7baZSR.webp"
      },
      {
         name:"Oven Story",
         tag:"Pizza Partner",
         site_url:"https://www.ovenstory.in/",
         logo_url:"####"
      },
      {
         name:"Paruthee handlooms",
         tag:"Handloom Partner ",
         site_url:"https://www.paruthee.com/",
         logo_url:"https://iili.io/J7babAN.webp"
      },
      {
         name:"Printwear ",
         tag:"Merchandise Partner",
         site_url:"https://printwear.in/",
         logo_url:"https://iili.io/J7baQcv.webp"
      },
      {
         name:"SBI Card",
         tag:"Banking and Investments Partner",
         site_url:"https://www.sbicard.com/",
         logo_url:"https://iili.io/JY51Rqu.webp"
      },
      {
         name:"SBI Wealth",
         tag:"Wealth Management Partner",
         site_url:"https://sbi.co.in/web/wealth-management",
         logo_url:"https://iili.io/J7bcfHl.webp"
      },
      {
         name:"Shankar IAS",
         tag:"Civil Services Education Sponsor",
         site_url:"https://www.shankariasacademy.com/",
         logo_url:"https://iili.io/J7baLKJ.webp"
      },
      {
         name:"Slaylewks",
         tag:"Digital Beauty Partner",
         site_url:"https://slaylewks.com/",
         logo_url:"https://iili.io/J7baD9p.webp"
      },
      {
         name:"Sparx",
         tag:"Footwear Partner",
         site_url:"https://www.relaxofootwear.com/",
         logo_url:"https://iili.io/JakwaCx.webp"
      },
      {
         name:"Suzuki Motors",
         tag:"Two Wheelers Partner",
         site_url:"https://www.natwestsuzuki.com/",
         logo_url:"https://iili.io/J7baiPa.webp"
      },
      {
         name:"City Union Bank",
         tag:"Quality Banking Partner",
         site_url:"",
         logo_url:"https://iili.io/J7bc2KG.webp"
      },
      {
         name:"The Legacy Closet",
         tag:"Fandom Merch Sponsor",
         site_url:"https://www.instagram.com/the_legacy_closet_india/",
         logo_url:"https://iili.io/JY51adx.webp"
      },
      {
         name:"The original knit",
         tag:"crocheting sponsor",
         site_url:"",
         logo_url:"####"
      },
      {
         name:"Top One Percent",
         tag:"Global Education Partner",
         site_url:"https://www.top-one-percent.com/",
         logo_url:"https://iili.io/JY51use.webp"
      },
      {
         name:"Kyn",
         tag:"",
         site_url:"",
         logo_url:"####"
      },
      {
         name:"Deomoda",
         tag:"Gifting Partner",
         site_url:"",
         logo_url:"https://iili.io/J7ba6oF.webp"
      },
      {
         name:"The Madras Kneads",
         tag:"Breads and Buns Partner",
         site_url:"https://www.instagram.com/themadraskneads/",
         logo_url:"https://iili.io/J7bagwP.webp"
      },
      {
         name:"Gone Mad",
         tag:"Wafers Sponsor",
         site_url:"http://www.gpfoods.co.in",
         logo_url:"https://iili.io/J7bcFS4.webp"
      },
      {
         name:"Valorant",
         tag:"-",
         site_url:"https://www.riotgames.com/en",
         logo_url:"https://iili.io/J7baPMg.webp"
      },
      {
         name:"Beanly ",
         tag:"Cold Coffee Partner",
         site_url:"",
         logo_url:"####"
      },
      {
         name:"Boardroom ",
         tag:"Boardgame sponsor",
         site_url:"",
         logo_url:"https://iili.io/J7bart1.webp"
      },
      {
         name:"Chennaiyin FC",
         tag:"Official Football Sponsor",
         site_url:"www.chennaiyinfc.com",
         logo_url:"https://iili.io/J7b4Q0g.webp"
      },
      {
         name:"CM Eco Pens",
         tag:"Pen Sponsor",
         site_url:"https://www.cmecopens.com/",
         logo_url:"https://iili.io/J7b4vUb.webp"
      },
      {
         name:"Crea-Shakthi",
         tag:"Theatre Sponsor",
         site_url:"https://creashakthi.com/",
         logo_url:"https://iili.io/J7b4LqF.webp"
      },
      {
         name:"Decathlon ",
         tag:"Sports Sponsor ",
         site_url:"",
         logo_url:"####"
      },
      
      {
         name:"Dreamzone",
         tag:"Design sponsor",
         site_url:"",
         logo_url:"####"
      },
      {
         name:"Earthy Way",
         tag:"Healthy Ladoo Partner ",
         site_url:"https://www.earthyway.in",
         logo_url:"https://iili.io/J7b4MJf.webp"
      },
      {
         name:"EaseMyTrip",
         tag:"Travel Partner",
         site_url:"https://www.easemytrip.com/",
         logo_url:"https://iili.io/J7b4wW7.webp"
      },
      {
         name:"Eazydiner",
         tag:"Online Dining Partner",
         site_url:"https://www.eazydiner.com/",
         logo_url:"https://iili.io/J7b4PWP.webp"
      },
      {
         name:"Gifting Essentials ",
         tag:"-",
         site_url:"https://www.instagram.com/giftingessentials.in/",
         logo_url:"https://iili.io/JY51leV.webp"
      },
      {
         name:"Fast Track",
         tag:"Travel Partner",
         site_url:"https://www.fasttrackcalltaxi.in/",
         logo_url:"https://iili.io/JY510mB.webp"
      },
      {
         name:"Gustora",
         tag:"Pasta partner ",
         site_url:"https://www.gustorafoods.com/",
         logo_url:"https://iili.io/J7b4is1.webp"
      },
      {
         name:"HypeKicks",
         tag:"Sneakers Partner",
         site_url:"https://hypekicks.in",
         logo_url:"https://iili.io/J7b40fn.webp"
      },
      {
         name:"Iris glam",
         tag:"fashion partner ",
         site_url:"",
         logo_url:"####"
      },
      {
         name:"Jiosaavn",
         tag:"Official music streaming partner ",
         site_url:"https://www.jiosaavn.com/",
         logo_url:"https://iili.io/J7b4gOQ.webp"
      },
      {
         name:"Kaytna Jewellery",
         tag:"Jewellery partner ",
         site_url:"https://www.instagram.com/kaytna_jewellery/",
         logo_url:"https://iili.io/J7b4U5x.webp"
      },
      {
         name:"KKIX Customs",
         tag:"Customized Sneakers Partner",
         site_url:"https://kkix.in/",
         logo_url:"https://iili.io/J7b4k0u.webp"
      },
      {
         name:"Kocoatrait",
         tag:"Kocoatrait",
         site_url:"Kocoatrait",
         logo_url:"https://iili.io/J7b4WOl.webp"
      },
      {
         name:"Kommuneity",
         tag:"Performance Arts Partner",
         site_url:"https://www.instagram.com/kommuneitychennai?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw==",
         logo_url:"https://iili.io/JYo68rP.webp"
      },
      {
         name:"Lotte",
         tag:"Confectionary Sponsor",
         site_url:"https://www.lotteindia.com/choco-pie.html",
         logo_url:"https://iili.io/JYo6U21.webp"
      },
      {
         name:"MacV",
         tag:"Official Sunglasses Partner",
         site_url:"https://macv.in/",
         logo_url:"https://iili.io/J7b4SJj.webp"
      },
      {
         name:"Mistletoe",
         tag:"-",
         site_url:"https://www.instagram.com/mistletoe_by_anu_prosper/?hl=en",
         logo_url:"https://iili.io/J7b46zB.webp"
      },
      {
         name:"Niche Films",
         tag:"Drone partner ",
         site_url:"https://nichefilms.in/",
         logo_url:"https://iili.io/J7b4Ns9.webp"
      },
      {
         name:"Nutribs",
         tag:"skincare sponsor",
         site_url:"",
         logo_url:"####"
      },
      {
         name:"Petals India",
         tag:"Co Sponsor",
         site_url:"https://petals-india.com/",
         logo_url:"https://iili.io/J7b4jxS.webp"
      },
      {
         name:"PLSH Salon",
         tag:"Salon Partner",
         site_url:"https://plshair.com/",
         logo_url:"https://iili.io/J7b4rbV.webp"
      },
      {
         name:"Plum Goodness",
         tag:"Vegan Beauty Partner",
         site_url:"Plum Goodness | 100% Vegan & Toxin-Free Products | For Men & Women", 
         logo_url:"https://iili.io/JY517gj.webp"
      },
      {
         name:"Srishti Publishers and Distributors",
         tag:"Publishing Partner",
         site_url:"https://www.srishtipublishers.com/",
         logo_url:"https://iili.io/J7b4efe.webp"
      },
      {
         name:"T tattle",
         tag:"Accessories partner ",
         site_url:"https://www.instagram.com/t.tattle/",
         logo_url:"https://iili.io/J7b4EUG.webp"
      },
      {
         name:"Taj Coromandel",
         tag:"Luxury Accommodation partner",
         site_url:"https://www.tajhotels.com/en-in/taj/taj-coromandel-chennai/",        
         logo_url:"https://iili.io/J7b4Xb2.webp"
      },
      {
         name:"The Cake Therapy",
         tag:"Bakery Partner",
         site_url:"",
         logo_url:"https://iili.io/J7b41ls.webp"
      },
      {
         name:"The Huda Bar",
         tag:"Healthy Protein Bars Partner",
         site_url:"https://www.thehudabar.com",
         logo_url:"https://iili.io/J7b4ciX.webp"
      },
      {
         name:"The Madras Kneads",
         tag:"Buns and Breads Partner",
         site_url:"https://www.instagram.com/themadraskneads/",
         logo_url:"####"
      },
      {
         name:"The Manga Crew",
         tag:"The Manga Crew",
         site_url:"https://www.themangacrew.com/",
         logo_url:"https://iili.io/J7bLfGS.webp"
      },
      {
         name:"The Smart Grain Company",
         tag:"Healthy Pasta Partner",
         site_url:"https://www.smartgraincompany.com",
         logo_url:"https://iili.io/J7bLTwx.webp"
      },
      {
         name:"Too Yumm",
         tag:"Snacking Partner",
         site_url:"https://www.tooyumm.com/",
         logo_url:"https://iili.io/J7bL19a.webp"
      },
      {
         name:"Nuvue Store",
         tag:"Nuvue Store",
         site_url:"https://nuvuestore.link/",
         logo_url:"https://iili.io/J7bLRnV.webp"
      },
      {
         name:"Colorashram",
         tag:"Tie-Dye Partner",
         site_url:"https://www.colorashram.com/",
         logo_url:"https://iili.io/J7bLl8g.webp"
      },
      {
         name:"Mudskipper",
         tag:"-",
         site_url:"https://www.instagram.com/mmudskipperr?igsh=ZWt4OWprenV6MzVo",       
         logo_url:"https://iili.io/J7bLo8u.webp"
      },
      {
         name:"Unibic",
         tag:"Biscuit Sponsor",
         site_url:"https://unibicestore.com/collections/cookies",
         logo_url:"https://iili.io/JY51c5Q.webp"
      },
      {
         name:"Meraki Blossom ",
         tag:"-",
         site_url:"https://www.instagram.com/meraki_blossom?igsh=MTZ1eWpxdnU2ODdpMw==", 
         logo_url:"https://iili.io/J7bL76P.webp"
      },
      {
         name:"Chennaiyil",
         tag:"Online Media Partner",
         site_url:"",
         logo_url:"####"
      },
      {
         name:"Groovenexus",
         tag:"Digital Media Sponsor ",
         site_url:"https://www.groovenexus.com/",
         logo_url:"https://iili.io/J7bLxyb.webp"
      },
      {
         name:"Knowafest",
         tag:"Online Media Partner",
         site_url:"https://www.knowafest.com/explore/events",
         logo_url:"https://iili.io/J7bLIuj.webp"
      },
      {
         name:"Radio City ",
         tag:"Official Radio Partner",
         site_url:"",
         logo_url:"https://iili.io/J7bLCF9.webp"
      },
      {
         name:"Raga To Rock",
         tag:"Online Media Partner",
         site_url:"",
         logo_url:"####"
      },
      {
         name:"The Indian Music Diaries ",
         tag:"Online Media Partner ",
         site_url:"https://theindianmusicdiaries.com/",
         logo_url:"https://iili.io/J7bLccF.webp"
      },
      {
         name:"radio mirchi",
         tag:"",
         site_url:"",
         logo_url:"####"
      },
      {
         name:"suryan fm ",
         tag:"worldfest radio partner",
         site_url:"",
         logo_url:"https://iili.io/J7bL3Zl.webp"
      },
      {
         name:"new to chennai ",
         tag:"",
         site_url:"",
         logo_url:"https://iili.io/J7bLq67.webp"
      },
      {
         name:"Score Magazine",
         tag:"Online Media Partner",
         site_url:"https://highonscore.com/",
         logo_url:"https://iili.io/J7bLnae.webp"
      },
      {
         name:"Youth Incorporated",
         tag:"Online Media Partner",
         site_url:"https://youthincmag.com/",
         logo_url:"https://iili.io/J7bLKn2.webp"
      },
      {
         name:"Teach for India",
         tag:"",
         site_url:"",
         logo_url:"https://iili.io/J7bL5MB.webp"
      },
      {
         name:"Sustainibility next",
         tag:"",
         site_url:"",
         logo_url:"https://iili.io/J7bL2j4.webp"
      },
      {
         name:"Entrench Electronics",
         tag:"Urjam Workshop Partner",
         site_url:"",
         logo_url:"https://iili.io/J7bLaF1.webp"
      }
    ]

   

    return (
        <div className="spons-container">
            <div className="heading"> Sponsors </div>
            
            <div className="spons-grid">
                    {sponsors.map((sponsor)=>(
                        <div className="sponsor-card"><a href={sponsor.site_url} target="_blank">
                            <img src={sponsor.logo_url != "####"? sponsor.logo_url: "https://iili.io/JYMpa3X.png"} />
                            <div className="sponsor-name">{sponsor.name}</div>
                            <div className="sponsor-tag">{sponsor.tag}</div>
                            </a></div>
                    ))}
                

            </div>
        </div>
    );
};

export default Sponsors;