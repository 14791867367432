import React, { useEffect } from "react";
import { useGetMe_QueryQuery } from "../generated/graphql";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import client from "../Graphql/index"

interface UserContext {
  state: any;
  signUp: (_user: {
    user_id: string;
    positions: string;
    username: string;
  }) => void;
  signIn: (_user: {
    user_id: string;
    positions: string;
    username: string;
  }) => void;
  signOut: () => void;
}
const AuthContext = React.createContext<UserContext | null>(null);

function AuthContextProvider(props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {
  const { data, loading, error } = useGetMe_QueryQuery({
    fetchPolicy: "no-cache",
  });

  const [state, dispatch] = React.useReducer(
    (
      prevState: any,
      action: {
        type: any;
        user?:
          | { user_id: string; positions: string; username: string }
          | undefined;
      }
    ) => {
      switch (action.type) {
        case "RESTORE_TOKEN":
          return {
            ...prevState,
            user: action.user,
            isLoading: false,
          };
        case "SIGN_IN":
          return {
            ...prevState,
            user: action.user,
            isLoading: false,
          };
        case "SIGN_UP":
          return {
            ...prevState,
            user: action.user,
            isLoading: false,
          };
        case "SIGN_OUT":
          return {
            ...prevState,
            user: undefined,
            isLoading: false,
          };
      }
    },
    {
      isLoading: true,
      user: null,
    }
  );

  React.useEffect(() => {
    if (error) dispatch({ type: "SIGN_OUT" });
    if(data){
      if (!loading && data.getMe_query)
      dispatch({
        type: "RESTORE_TOKEN",
        user: data.getMe_query,
      });
    }
  }, [data, loading, error]);

  const authContext = React.useMemo(
    () => ({
      state,
      signIn: (_user: {
        user_id: string;
        positions: string;
        username: string;
      }) => {
        dispatch({
          type: "SIGN_IN",
          user: _user,
        });
        client.resetStore();
      },
      signUp: (_user: {
        user_id: string;
        positions: string;
        username: string;
      }) => {
        dispatch({
          type: "SIGN_UP",
          user: _user,
        });
        client.resetStore();
        // window.location.href = "/verifyEmail";
      },
      signOut: () => {
        dispatch({ type: "SIGN_OUT" });
        window.location.reload();
      },
    }),
    [state]
  );

  return (
    <AuthContext.Provider value={authContext}>
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthContext;
export { AuthContextProvider };