import React, { useState } from "react";

const name = "saarang-main";
const PortalContext = React.createContext<string>(name);

function PortalContextProvider(props: {
  children:
    | boolean
    | React.ReactChild
    | React.ReactFragment
    | React.ReactPortal
    | null
    | undefined;
}) {

  return (
    <PortalContext.Provider
      value={name}
    >
      {props.children}
    </PortalContext.Provider>
  );
}

export default PortalContext;
export { PortalContextProvider };