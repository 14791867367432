import React from "react";
import "./contact.css";

function ContactUs() {
    return (
        <div className="Contact-container">

            
            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Cultural Affairs Secretaries</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Valeti Sriraj <br/>
                        sec_lit@smail.iitm.ac.in <br/>
                    </div>
                    <div className="Contact-card">
                        Jyotir Aditya Menon <br/>
                        sec_arts@smail.iitm.ac.in <br/>
                    </div>    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Events</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Aaromal S Keshav <br/>
                        events@saarang.org <br/>
                    </div>
                    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Development Operations</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Bharath Reddy <br/>
                        devops@saarang.org <br/>
                    </div>
                    <div className="Contact-card">
                        Hari Charan Korrapati <br/>
                        devops@saarang.org <br/>
                    </div>
                    <div className="Contact-card">
                        Bhagvat Girish <br/>
                        devops@saarang.com <br/>
                    </div>    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Facilities and Requirements</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        P Navin Kumar Adhithyan <br/>
                       fr@saarang.org <br/>
                    </div>
                    <div className="Contact-card">
                        Gugulothu Naveen <br/>
                        fr@saarang.org <br/>
                    </div>
                    <div className="Contact-card">
                        Patlolla Vaishnavi <br/>
                        fr@saarang.org <br/>
                    </div>    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Finance</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Satya Sai Charan Budhavarapu <br/>
                        finance@saarang.org <br/>
                    </div>
                    <div className="Contact-card">
                        Yalpi Samia Tahseen <br/>
                        finance@saarang.org <br/>
                    </div>    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Marketing and Sales</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Narasimha <br/>
                        mns@saarang.org<br/>
                    </div>
                    <div className="Contact-card">
                        Rachakonda Asritha <br/>
                        mns@saarang.org<br/>
                    </div>    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Media, Ambience & Design</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Boda Sindhu Priyadharshini <br/>
                            mad@saarang.org <br/>
                    </div>
                    <div className="Contact-card">
                        A Keerthi <br/>
                        mad@saarang.org <br/>
                    </div>
                    <div className="Contact-card">
                        Ajoe George <br/>
                        mad@saarang.org <br/>
                    </div>    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Professional Shows</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Bhagat Singh S <br/>
                        professionalshows@saarang.org<br/>
                    </div>
                    <div className="Contact-card">
                        Shreya Rajesh <br/>
                        professionalshows@saarang.org<br/>
                    </div>    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Publicity and Hospitality</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Gummadi Sanjay <br/>
                        studentrelations@saarang.org<br/>
                    </div>
                    <div className="Contact-card">
                        Seetha Tarun <br/>
                        studentrelations@saarang.org<br/>
                    </div>    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Quality Management System</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Devulapally Ujwala <br/>
                        qms@saarang.org <br/>
                    </div>   
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Safety and Security</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Krishna Sai Kottakota <br/>
                        sns@saarang.org<br/>
                    </div>
                    <div className="Contact-card">
                        Tejavath Sai Yashasri <br/>
                        sns@saarang.org<br/>
                    </div>    
                </div>    
            </div>

            <div className="Contact-vertical">
                <div className="Contact-verticalheading">
                    <span>Sponsorship & Public Relations</span>
                </div>
                <div className="Contact-cardcontainer">
                    <div className="Contact-card">
                        Abhinav P S <br/>
                        publicrelations@saarang.org <br/>
                    </div>
                    <div className="Contact-card">
                        Sharanya Kannan <br/>
                        publicrelations@saarang.org <br/>
                    </div>    
                </div>    
            </div>
            <div className="feedback-container">
                For any queries or complaints : <a className="form-link" href="https://forms.gle/wzPBzy8GsX14ErFM8" target="_blank">Report an Issue</a>
            </div>
            
        </div>
    )
}



export default ContactUs ;