import axios from "axios";
import * as React from "react";
import { useParams } from "react-router-dom";
import "./event_desp.css";
import useRazorpay from "react-razorpay";
import { FiAlertTriangle } from "react-icons/fi";

axios.defaults.withCredentials = true;
interface Event_DespProps {
  id: string;
  title: string;
  club_name: string;
  description: string;
  image_url: string;
  rules: string[];
  event_date: string;
  judge_name: string;
  judge_image: string;
  submission_link?: string;
  event_category: string;
  type: string,
  price: number,
  registrations_closed: boolean,
  //event_location : string,
  notes: {
    type: string;
    user_id: string;
    event_id: string;
  }
}
const Event_Desp: React.FunctionComponent = () => {
  const Razorpay = useRazorpay();
  const id = useParams().id;

  const [event, setEvent] = React.useState<Event_DespProps>();
  const [isRegistered, setIsRegistered] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  // extract date and time from YYYY-MM-DDTHH:MM:SSZ to Jan DD, YYYY and HH:MM AM/PM
  // Not in UTC format

  const extractDateTime = (dateTime: string | undefined) => {
    if (dateTime) {
      const date = new Date(dateTime);
      const month = date.toUTCString().split(" ")[2];

      const day = date.getUTCDate();

      // const hours = date.getUTCHours();
      // const minutes = date.getUTCMinutes();
      // const ampm = hours >= 12 ? "PM" : "AM";
      // const hour = hours % 12;
      // const min = minutes < 10 ? "0" + minutes : minutes;

      // return `${month} ${day}, ${date.getFullYear()} at ${hour}:${min} ${ampm}`;
      return `${month} ${day}, ${date.getFullYear()}`;
    }
  };

  // Covert paragraph to array of strings for rules Based on full stop and remove empty strings
  const convertToRules = (rules: string | undefined) => {
    if (rules) {
      const rulesArray = rules.split(".");
      const filteredRules = rulesArray.filter((rule) => rule !== "");
      return filteredRules;
    } else return [];
  };

  React.useEffect(() => {
    try {
      axios({
        url: "https://saarang.org/api2/",
        // url: "http://localhost:8001/graphql",
        method: "post",
        data: {
          query: `    
                query GetEventbyId($eventId: String!) {
                    getEventbyId(EventId: $eventId) {
                    event {
                        id
                        event_category
                        club_name
                        title
                        tagline
                        description
                        image_url
                        rules
                        event_date
                        judge_name
                        judge_image
                        submission_link
                        type
                        price
                        registrations_closed
                    }
                    has_registered
                    }
                }
                `,
          variables: {
            eventId: id,
          },
        },
      }).then((result) => {
        // console.log("Data:", result.data);
        setEvent(result.data.data.getEventbyId.event);
        setIsRegistered(result.data.data.getEventbyId.has_registered);
      });
    } catch (err) {
      console.log(err);
    }
  }, []);

  const handleRegister = () => {
    try {
      if (event?.type == "workshop" && event?.price > 0) {
        axios({
          url: "https://saarang.org/api2/",
          // url: "http://localhost:8001/graphql",
          method: "post",
          data: {
            query: `
            mutation MerakiOrder($merakiOrderId: String!) {
              merakiOrder(id: $merakiOrderId) {
                amount
                id
              }
            }
            `,
            variables: {
              merakiOrderId: id,
            }
          }
        }).then((result) => {
          console.log(result.data);
          if (!result.data.errors) {
            const order = result.data.data.merakiOrder;
            const options = {
              key: "rzp_live_VgVO9uzPsxViN5",
              // key: "rzp_test_wU5yfvuVKhaCWD",
              notes: {
                type: "workshop",
                user_id: "",
                event_id: id
              },
              amount: order.amount,
              order_id: order.id,
              name: "Saarang 2023",
              currency: "INR",
              payment_capture: "true",
              image: "https://saarang.org/static/media/logo.5d5d9eef.svg",
              handler: function (response: any) {
                setIsLoading(true);
                axios({
                  url: "https://saarang.org/api2/",
                  // url: "http://localhost:8001/graphql",
                  method: "post",
                  data: {
                    query: `
                    mutation MerakiPayment($razorpayOrderId: String!, $razorpayPaymentId: String!, $razorpaySignature: String!, $merakiPaymentId: String!) {
                      merakiPayment(razorpay_order_id: $razorpayOrderId, razorpay_payment_id: $razorpayPaymentId, razorpay_signature: $razorpaySignature, id: $merakiPaymentId)
                    }`,
                    variables: {
                      razorpayOrderId: response.razorpay_order_id,
                      razorpayPaymentId: response.razorpay_payment_id,
                      razorpaySignature: response.razorpay_signature,
                      merakiPaymentId: id,
                    }
                  }
                }).then((result) => {
                  // if(result.data.data)
                  console.log(result.data);
                  if (result.data.errors) alert("Registration Failed");
                  else {
                    alert("Registered successfully");
                    setIsRegistered(true);
                  }
                  setIsLoading(false);
                })
              }
            }
            const rzp1 = new Razorpay(options);
            rzp1.open();
          }
          else {
            alert(result.data.errors[0].message);
          }
        })
      } else {
        axios({
          url: "https://saarang.org/api2/",
          // url: "http://localhost:8001/graphql",
          method: "post",
          data: {
            query: `
          mutation RegisterUserForEvent($clubName: String!, $eventId: String!) {
            registerUserForEvent(ClubName: $clubName, EventId: $eventId)
          }
                `,
            variables: {
              clubName: event?.club_name,
              eventId: id,
            },
          },
        }).then((result) => {
          // console.log(result.data);
          if (result.data.data) setIsRegistered(true);
          else {
            alert(result.data.errors[0].message);
            if (result.data.errors[0].message === "You don't have a pass for this event") {
              window.location.href = `https://saarang.org/events/${event?.event_category}`;
            }
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) return <div style={{
    height: "100vh",
    width: "100vw",
    padding: "10%",
  }}><h1 style={{ margin: "auto" }}> Please wait while payment is processing ...</h1></div>

  return (
    <div className="event-desp-page">
      {/* <NavBar></NavBar> */}
      <a href='https://saarang.org/registeredevents' className='registered-events'>My Registrations <i className='arrow button-right'></i> </a>
      {/* {isOpen &&
        <div className="popup-box">
          <div className="popup-contentbox">
            <FiAlertTriangle className="icon" />
            <ul>
              Event Registration is free for IITM students. Login with your IITM email id to register.
            </ul>
          </div>
          <button id="close-button" onClick={() => { togglePopup() }} >x</button>
        </div>
      } */}
      <div style={{ height: "100px" }}></div>
      <h3 className="title">{event?.title}</h3>
      <p className="club-name-d">{event?.club_name}</p>
      {isRegistered ? (
        <a className="submission_form" href={event?.submission_link}>
          {event?.submission_link ? "Click here for Submission form" : null}{" "}
        </a>
      ) : null}
      <div className="button-box">
        {isRegistered ? (
          <p className="e_registered">You are registered for this event</p>
        ) : (
          event?.registrations_closed ? (
            <p className="e_registered" style={{ 'color': 'white' }}>Registrations Closed</p>
          ) :
            <button
              onClick={handleRegister}
              className="reg-button"
              style={{ cursor: "pointer" }}
            >
              Register {event?.type == "workshop" ? "₹" + event?.price : null}
            </button>
        )}
      </div>
      {/* <h4 className="tagline">{event?.tagline}</h4> */}
      <div className="img-border">
        <img src={event?.image_url} className="event-img" alt="" />
      </div>
      <div className="datetime">
        <a className="dt_text">{extractDateTime(event?.event_date)}</a>
      </div>
      <div className="content-box">
        <p className="desp">{event?.description}</p>
        {(event?.rules[0])===''?null:
        <div className="rules_box">
          Rules and Regulations
          {convertToRules(event?.rules[0]).map((event) => (
            <li className="rule_text">{event}</li>
          ))}
          {/* {event?.rules.map((event) => (
            <li className="rule_text">{event}</li>
          ))} */}
        </div>}
      </div>
    </div>
  );
};

export default Event_Desp;
