import { useRef } from 'react';
import { useCreateEventMutation } from '../../generated/graphql';
import './putEvents.css';

const PutEvent: React.FC = () => {

  const eventInput = useRef<null | HTMLFormElement>(null);



  const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
  });



  const onSubmit = (e: any) => {
    e.preventDefault();
    const form = eventInput.current;

    if (form) {
      createEventMutation({
        variables: {
          "data": {
            'club_name': form['clubs_name'].value,
            'description': form['description'].value,
            'event_category': form['events_category'].value,
            "event_date": form['date_time'].value,
            "image_url": form['poster_url'].value,
            "judge_image": form['judge_image_url'].value,
            "judge_name": form['judge_name'].value,
            "rules": form['rules'].value,
            'title': form['event_title'].value,
            'tagline': form['tagline'].value,
            'submission_link': form['submission_link'].value,
            'type': form['type'].value,
            'price': parseInt(form['price'].value),
            "event_location": form['event_location'].value,
          }
        }
      })


    }

  };

  return (
    <div className="register-form">
      <form onSubmit={onSubmit} ref={eventInput}>
        <div className='form-box'>
          <h2>
            Event
          </h2>
          <div className="form-group">
            <label>Event Category</label>
            <select name="event_category" id="events_category">
              <option value="performing">Performing Arts</option>
              <option value="literary">Literary Arts</option>
              <option value="creative">Creative Arts</option>
              <option value="digital">Digital Arts</option>
              <option value="nova">Nova</option>
              <option value="noncompetitive">Non Competitive Events</option>
            </select>
          </div>
          <div className="form-group">
            <label>Club Name</label>
            <select name="club_name" id="clubs_name">
              <option value="choreo">Choreo</option>
              <option value="classicalarts">Classical Arts</option>
              <option value="drama">Drama</option>
              <option value="music">Music</option>
              <option value="comedy">Comedy</option>
              <option value="quiz">Quiz</option>
              <option value="oratory">Oratory</option>
              <option value="wordgames">Word Games</option>
              <option value="writing">Writing</option>
              <option value="fashion">Fashion</option>
              <option value="finearts">Fine Arts</option>
              <option value="culinary">Culinary</option>
              <option value="designvfx">Design & VFX</option>
              <option value="esports">E-Sports</option>
              <option value="media">Media</option>
              <option value="informals">Informals</option>
              <option value="spotlight">Spotlight</option>
              <option value="timetruck">Time Truck </option>
              <option value="timetrek">Time Trek </option>
              <option value="incredibleindia">Incredible India</option>
              <option value="multiverse">Multiverse</option>
              <option value="meraki">Meraki</option>
            </select>
          </div>
          <div className="form-group">
            <label>Title</label>
            <input type="text" name='event_title' required />
          </div>
          <div className="form-group">
            <label>Tagline</label>
            <input type="text" name='tagline' required />
          </div>
          <div className="form-group">
            <label>Poster URL</label>
            <input type="text" name='poster_url' required />
          </div>
          <div className="form-group">
            <label>Description</label>
            <textarea rows={10} cols={53} name="description" placeholder="Enter..."></textarea>
            {/* <input style={{ 'height': '200px' }} type="text" name='description' required /> */}
          </div>
          <div className="form-group">
            <label>Rules and Regulations</label>
            <textarea rows={10} cols={53} name="rules" placeholder="Add full stop after each rule... "></textarea>
            {/* <input style={{ 'height': '200px' }} type="text" name='rules' required /> */}
          </div>

          <div className="form-group">
            <label>Date and Time</label>
            <input type="datetime-local" name='date_time' required />
          </div>
          <div className="form-group">
            <label>Submission Form Link</label>
            <input type="text" name='submission_link' placeholder='Leave blank if does not apply' />
          </div>
          <div className="form-group">
            <label>Judge Name</label>
            <input type="text" name='judge_name' required />
          </div>
          <div className="form-group">
            <label>Judge Image URL</label>
            <input type="text" name='judge_image_url' required />
          </div>
          <div className="form-group">
            <label>Type</label>
            <select name="type" id="type">
              <option value="event">Event</option>
              <option value="workshop">Workshop</option>
            </select>
          </div>
          <div className="form-group">
            <label>Price</label>
            <input type="number" name='price' />
          </div><div className="form-group">
            <label>Location</label>
            <input type="text" name='event_location' />
          </div>
          <div className="form-group-submit">
            {loading ? <p style={{ 'color': 'black', 'textAlign': 'center' }}>Adding..</p> : <button type="submit" className="btn btn-primary">
              Submit
            </button>}
            <p>&nbsp;
              {error !== undefined ? <p style={{ 'color': 'red', 'textAlign': 'center' }}>Error: {error.message}</p> : <p style={{ 'color': 'green', 'textAlign': 'center' }}>{data?.createEvent == true ? "Event Added successfully" : ""}</p>}
            </p>

          </div>
        </div>

      </form>

      {/* <div className='form-box'>
        <h2>Club Pass</h2>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Club Name</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Price</label>
            <input type="text" />
          </div>
          <div className="form-group-submit">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </div>
        </form>
      </div> */}


      {/* <div className='form-box'>
        <div>
          <h1>Sponsors</h1>
        </div>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <label>Company Name</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <label>Image URL</label>
            <input type="text" />
          </div>
          <div className="form-group">
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
            <h1>

            </h1>
          </div>
        </form>
      </div> */}
    </div>
  );
};

export default PutEvent ;
