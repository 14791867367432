import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: any;
};

export type Accomodation = {
  Departure: Scalars['DateTime'];
  amount: Scalars['Float'];
  arrival: Scalars['DateTime'];
  college_name: Scalars['String'];
  id: Scalars['String'];
  leader_name: Scalars['String'];
  mobile_no: Scalars['String'];
  name: Scalars['String'];
  num_females: Scalars['Float'];
  num_males: Scalars['Float'];
  num_tickets_booked: Scalars['Float'];
  razorpay_order_id: Scalars['String'];
  razorpay_payment_id: Scalars['String'];
  user: Users;
  verified: Scalars['Boolean'];
};

export type AwardPointsInput = {
  points_awarded: Scalars['Float'];
};

export type ClubEventResponse = {
  club_name: Scalars['String'];
  club_pass_price: Scalars['Float'];
  events: Array<Event>;
  has_pass: Scalars['Boolean'];
};

export type CreateAccomodationInput = {
  Departure: Scalars['DateTime'];
  arrival: Scalars['DateTime'];
  college_name: Scalars['String'];
  coupon_code?: InputMaybe<Scalars['String']>;
  leader_name: Scalars['String'];
  mobile_no: Scalars['String'];
  name: Scalars['String'];
  num_females: Scalars['Float'];
  num_males: Scalars['Float'];
  num_tickets_booked: Scalars['Float'];
};

export type CreateSubmissionInput = {
  points_awarded?: InputMaybe<Scalars['Float']>;
  task_id: Scalars['String'];
  task_proofs: Scalars['String'];
  verification_status: Scalars['Boolean'];
};

export type EditSubmissionInput = {
  points_awarded?: InputMaybe<Scalars['Float']>;
  task_proofs?: InputMaybe<Scalars['String']>;
};

export type Event = {
  club_name: Scalars['String'];
  description: Scalars['String'];
  event_category: Scalars['String'];
  event_date: Scalars['DateTime'];
  event_location?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  image_url: Scalars['String'];
  judge_image: Scalars['String'];
  judge_name: Scalars['String'];
  price?: Maybe<Scalars['Float']>;
  registrations_closed?: Maybe<Scalars['Boolean']>;
  rules: Array<Scalars['String']>;
  submission_link?: Maybe<Scalars['String']>;
  tagline: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type EventInput = {
  club_name: Scalars['String'];
  description: Scalars['String'];
  event_category: Scalars['String'];
  event_date: Scalars['DateTime'];
  event_location: Scalars['String'];
  image_url: Scalars['String'];
  judge_image: Scalars['String'];
  judge_name: Scalars['String'];
  price?: InputMaybe<Scalars['Float']>;
  registrations_closed?: InputMaybe<Scalars['Boolean']>;
  rules: Array<Scalars['String']>;
  submission_link?: InputMaybe<Scalars['String']>;
  tagline: Scalars['String'];
  title: Scalars['String'];
  type: Scalars['String'];
};

export type Mutation = {
  addClubPass: Scalars['Boolean'];
  addCoupon: Scalars['Boolean'];
  addEvent: Scalars['Boolean'];
  add_checkout_fields: Scalars['Boolean'];
  awardPoints: Scalars['Boolean'];
  buyClubPass: Rp_Order;
  cancelAccomodation: Scalars['Boolean'];
  check_forgot: Scalars['Boolean'];
  check_reg: Users;
  commonRegister: Users;
  createEvent: Scalars['Boolean'];
  createSubmission: Submission;
  createTask: Task;
  deleteAccomodation: Scalars['Boolean'];
  deleteEvent: Scalars['Boolean'];
  deleteSubmission: Scalars['Boolean'];
  deleteTask: Scalars['Boolean'];
  editSubmission: Scalars['Boolean'];
  getAccomodationById: Accomodation;
  getSubmissionById: Submission;
  get_order_id: Rp_Order;
  login: Users;
  logout: Scalars['Float'];
  merakiOrder: Rp_Order;
  merakiPayment: Scalars['Boolean'];
  mut: Scalars['String'];
  register: Users;
  registerEvent: Rp_Order_Te;
  registerUserForEvent: Scalars['Boolean'];
  updateEvent: Scalars['Boolean'];
  updateTask: Scalars['Boolean'];
  verify_signature: Scalars['Boolean'];
  verify_signature_te: Scalars['Boolean'];
};


export type MutationAddClubPassArgs = {
  name: Scalars['String'];
  price: Scalars['Float'];
};


export type MutationAddCouponArgs = {
  count: Scalars['Float'];
  disc: Scalars['Float'];
  pass: Scalars['String'];
};


export type MutationAddEventArgs = {
  capacity: Scalars['Float'];
  date: Scalars['DateTime'];
  name: Scalars['String'];
  pass: Scalars['String'];
  price: Scalars['Float'];
  slot: Scalars['String'];
};


export type MutationAdd_Checkout_FieldsArgs = {
  coupon_code?: InputMaybe<Scalars['String']>;
  razorpay_order_id: Scalars['String'];
  razorpay_payment_id: Scalars['String'];
  razorpay_signature: Scalars['String'];
};


export type MutationAwardPointsArgs = {
  SubmissionID: Scalars['String'];
  UserID: Scalars['String'];
  data: AwardPointsInput;
};


export type MutationBuyClubPassArgs = {
  name: Scalars['String'];
};


export type MutationCancelAccomodationArgs = {
  id: Scalars['String'];
};


export type MutationCheck_ForgotArgs = {
  email: Scalars['String'];
  pass: Scalars['String'];
  token: Scalars['String'];
};


export type MutationCheck_RegArgs = {
  email_base64: Scalars['String'];
  login_hash: Scalars['String'];
};


export type MutationCommonRegisterArgs = {
  DOB: Scalars['DateTime'];
  college: Scalars['String'];
  college_email_id: Scalars['String'];
  college_location: Scalars['String'];
  email_primary: Scalars['String'];
  email_secondary?: InputMaybe<Scalars['String']>;
  mobile_number_primary: Scalars['String'];
  mobile_number_secondary?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  portal: Scalars['String'];
  positions?: InputMaybe<Scalars['String']>;
  region: Scalars['String'];
  username: Scalars['String'];
  year_of_study: Scalars['String'];
};


export type MutationCreateEventArgs = {
  data: EventInput;
};


export type MutationCreateSubmissionArgs = {
  data: CreateSubmissionInput;
};


export type MutationCreateTaskArgs = {
  deadline: Scalars['DateTime'];
  points: Scalars['Float'];
  task_desp: Scalars['String'];
  task_image: Scalars['String'];
  task_name: Scalars['String'];
};


export type MutationDeleteAccomodationArgs = {
  AccomodationID: Scalars['String'];
};


export type MutationDeleteEventArgs = {
  EventId: Scalars['String'];
};


export type MutationDeleteSubmissionArgs = {
  SubmissionID: Scalars['String'];
};


export type MutationDeleteTaskArgs = {
  taskID: Scalars['String'];
};


export type MutationEditSubmissionArgs = {
  SubmissionID: Scalars['String'];
  data: EditSubmissionInput;
  deadline: Scalars['DateTime'];
};


export type MutationGetAccomodationByIdArgs = {
  id: Scalars['String'];
};


export type MutationGetSubmissionByIdArgs = {
  id: Scalars['String'];
};


export type MutationGet_Order_IdArgs = {
  data: CreateAccomodationInput;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationMerakiOrderArgs = {
  id: Scalars['String'];
};


export type MutationMerakiPaymentArgs = {
  id: Scalars['String'];
  razorpay_order_id: Scalars['String'];
  razorpay_payment_id: Scalars['String'];
  razorpay_signature: Scalars['String'];
};


export type MutationRegisterArgs = {
  DOB?: InputMaybe<Scalars['DateTime']>;
  email_primary: Scalars['String'];
  email_secondary?: InputMaybe<Scalars['String']>;
  mobile_number_primary?: InputMaybe<Scalars['String']>;
  mobile_number_secondary?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  positions?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};


export type MutationRegisterEventArgs = {
  EventId: Scalars['String'];
  quantity: Scalars['Float'];
};


export type MutationRegisterUserForEventArgs = {
  ClubName: Scalars['String'];
  EventId: Scalars['String'];
};


export type MutationUpdateEventArgs = {
  EventID: Scalars['String'];
  data: EventInput;
};


export type MutationUpdateTaskArgs = {
  data: UpdateTaskInput;
  taskID: Scalars['String'];
};


export type MutationVerify_SignatureArgs = {
  razorpay_order_id: Scalars['String'];
  razorpay_payment_id: Scalars['String'];
  razorpay_signature: Scalars['String'];
};


export type MutationVerify_Signature_TeArgs = {
  razorpay_order_id: Scalars['String'];
  razorpay_payment_id: Scalars['String'];
  razorpay_signature: Scalars['String'];
};

export type Query = {
  addSponsor: Scalars['Boolean'];
  deadlineDone: Scalars['Boolean'];
  dummy?: Maybe<Scalars['String']>;
  forgotPassword: Scalars['Boolean'];
  getAllAccomodations: Array<Accomodation>;
  getAllEvents: Array<Event>;
  getAllSubmissions: Array<Task>;
  getClubPassSales: Array<Users>;
  getClubSales: Scalars['Float'];
  getEventbyId: GetEventResponse;
  getEvents: Array<ClubEventResponse>;
  getMe_query: Users;
  getMyAccomodations: Array<Accomodation>;
  getMyEvents: Array<Event>;
  getRegisteredUsersForEvent: Array<Users>;
  getSponsors: Array<Sponsor>;
  getTasks: Array<Task>;
  getTicketingEvents: Array<TicketingEvent>;
  getTicketingRegsBySlot: Array<TicketingReg>;
  getTicketingSalesStats: Array<SalesStat>;
  paymentissues: Array<Scalars['String']>;
  resolveDriveImages: Scalars['Boolean'];
  validateCoupon: Scalars['Float'];
};


export type QueryAddSponsorArgs = {
  img: Scalars['String'];
  name: Scalars['String'];
};


export type QueryDeadlineDoneArgs = {
  deadline: Scalars['DateTime'];
};


export type QueryForgotPasswordArgs = {
  email: Scalars['String'];
};


export type QueryGetAllEventsArgs = {
  day?: InputMaybe<Scalars['Float']>;
};


export type QueryGetClubPassSalesArgs = {
  ClubName: Scalars['String'];
};


export type QueryGetEventbyIdArgs = {
  EventId: Scalars['String'];
};


export type QueryGetEventsArgs = {
  EventCategory?: InputMaybe<Scalars['String']>;
};


export type QueryGetRegisteredUsersForEventArgs = {
  EventId: Scalars['String'];
};


export type QueryGetTicketingRegsBySlotArgs = {
  SlotId: Scalars['String'];
};


export type QueryValidateCouponArgs = {
  coupon_code: Scalars['String'];
};

export type Rp_Order = {
  amount: Scalars['Float'];
  coupon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type SalesStat = {
  date: Scalars['DateTime'];
  event_id: Scalars['String'];
  quantity_sold: Scalars['Float'];
  slot: Scalars['String'];
};

export type Sponsor = {
  image_url: Scalars['String'];
  name: Scalars['String'];
};

export type Submission = {
  created_at: Scalars['DateTime'];
  id: Scalars['String'];
  points_awarded?: Maybe<Scalars['Float']>;
  task: Task;
  task_proofs: Scalars['String'];
  updated_at: Scalars['DateTime'];
  user: Users;
  verification_status: Scalars['Boolean'];
};

export type Task = {
  created_at: Scalars['DateTime'];
  deadline: Scalars['DateTime'];
  getSubmissions: Array<Submission>;
  id: Scalars['String'];
  points: Scalars['Float'];
  submission: Array<Submission>;
  task_desp: Scalars['String'];
  task_image: Scalars['String'];
  task_name: Scalars['String'];
  updated_at: Scalars['DateTime'];
};

export type TicketingEvent = {
  capacity: Scalars['Float'];
  date: Scalars['DateTime'];
  id: Scalars['String'];
  is_full?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  price: Scalars['Float'];
  slot: Scalars['String'];
};

export type TicketingReg = {
  college?: Maybe<Scalars['String']>;
  email_primary?: Maybe<Scalars['String']>;
  mobile_number_primary?: Maybe<Scalars['String']>;
  quantity: Scalars['Float'];
  username: Scalars['String'];
};

export type UpdateTaskInput = {
  deadline?: InputMaybe<Scalars['DateTime']>;
  points?: InputMaybe<Scalars['Float']>;
  task_desp?: InputMaybe<Scalars['String']>;
  task_image?: InputMaybe<Scalars['String']>;
  task_name?: InputMaybe<Scalars['String']>;
};

export type Users = {
  DOB?: Maybe<Scalars['DateTime']>;
  accomodation?: Maybe<Accomodation>;
  college?: Maybe<Scalars['String']>;
  college_email_id?: Maybe<Scalars['String']>;
  college_location?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['DateTime']>;
  email_primary: Scalars['String'];
  email_secondary?: Maybe<Scalars['String']>;
  mobile_number_primary?: Maybe<Scalars['String']>;
  mobile_number_secondary?: Maybe<Scalars['String']>;
  points_scored?: Maybe<Scalars['Float']>;
  positions: Scalars['String'];
  profile_picture?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  saarang_id: Scalars['String'];
  submission?: Maybe<Submission>;
  updated_at?: Maybe<Scalars['DateTime']>;
  user_id: Scalars['String'];
  username: Scalars['String'];
  verified?: Maybe<Scalars['Boolean']>;
  year_of_study?: Maybe<Scalars['String']>;
};

export type GetEventResponse = {
  event: Event;
  has_registered: Scalars['Boolean'];
};

export type Rp_Order_Te = {
  amount: Scalars['Float'];
  id: Scalars['String'];
};

export type VerifyEmailMutationVariables = Exact<{
  loginHash: Scalars['String'];
  emailBase64: Scalars['String'];
}>;


export type VerifyEmailMutation = { check_reg: { user_id: string, username: string, positions: string } };

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = { logout: number };

export type CreateEventMutationVariables = Exact<{
  data: EventInput;
}>;


export type CreateEventMutation = { createEvent: boolean };

export type UpdateEventMutationVariables = Exact<{
  data: EventInput;
  eventId: Scalars['String'];
}>;


export type UpdateEventMutation = { updateEvent: boolean };

export type ForgotPasswordQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type ForgotPasswordQuery = { forgotPassword: boolean };

export type GetMe_QueryQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMe_QueryQuery = { getMe_query: { user_id: string, saarang_id: string, email_primary: string, email_secondary?: string | null, username: string, mobile_number_primary?: string | null, mobile_number_secondary?: string | null, positions: string, DOB?: any | null, verified?: boolean | null, profile_picture?: string | null, college?: string | null, college_email_id?: string | null, college_location?: string | null, year_of_study?: string | null, region?: string | null } };

export type GetAllEventsQueryVariables = Exact<{
  day?: InputMaybe<Scalars['Float']>;
}>;


export type GetAllEventsQuery = { getAllEvents: Array<{ title: string, id: string, event_date: any, club_name: string }> };

export type GetEventbyIdQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type GetEventbyIdQuery = { getEventbyId: { event: { id: string, event_category: string, club_name: string, title: string, tagline: string, description: string, image_url: string, rules: Array<string>, event_date: any, judge_name: string, judge_image: string, submission_link?: string | null, type: string, price?: number | null, event_location?: string | null, registrations_closed?: boolean | null } } };

export type GetMyEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyEventsQuery = { getMyEvents: Array<{ title: string, event_date: any, id: string }> };

export type GetRegisteredUsersForEventQueryVariables = Exact<{
  eventId: Scalars['String'];
}>;


export type GetRegisteredUsersForEventQuery = { getRegisteredUsersForEvent: Array<{ saarang_id: string, user_id: string, email_primary: string, username: string, mobile_number_primary?: string | null, college?: string | null }> };

export type GetSaarangIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSaarangIdQuery = { getMe_query: { user_id: string, saarang_id: string } };

export type GetClubPassSalesQueryVariables = Exact<{
  clubName: Scalars['String'];
}>;


export type GetClubPassSalesQuery = { getClubPassSales: Array<{ user_id: string, saarang_id: string, email_primary: string, username: string, mobile_number_primary?: string | null, college?: string | null }> };

export type GetTicketingEventsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTicketingEventsQuery = { getTicketingEvents: Array<{ id: string, name: string, slot: string, price: number, date: any, capacity: number, is_full?: boolean | null }> };

export type GetTicketingRegsBySlotQueryVariables = Exact<{
  slotId: Scalars['String'];
}>;


export type GetTicketingRegsBySlotQuery = { getTicketingRegsBySlot: Array<{ username: string, mobile_number_primary?: string | null, email_primary?: string | null, college?: string | null, quantity: number }> };

export type GetTicketingSalesStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTicketingSalesStatsQuery = { getTicketingSalesStats: Array<{ event_id: string, date: any, slot: string, quantity_sold: number }> };


export const VerifyEmailDocument = gql`
    mutation VerifyEmail($loginHash: String!, $emailBase64: String!) {
  check_reg(login_hash: $loginHash, email_base64: $emailBase64) {
    user_id
    username
    positions
  }
}
    `;
export type VerifyEmailMutationFn = ApolloReactCommon.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      loginHash: // value for 'loginHash'
 *      emailBase64: // value for 'emailBase64'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = ApolloReactCommon.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout
}
    `;
export type LogoutMutationFn = ApolloReactCommon.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = ApolloReactCommon.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = ApolloReactCommon.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const CreateEventDocument = gql`
    mutation CreateEvent($data: EventInput!) {
  createEvent(data: $data)
}
    `;
export type CreateEventMutationFn = ApolloReactCommon.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
      }
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = ApolloReactCommon.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
    mutation UpdateEvent($data: EventInput!, $eventId: String!) {
  updateEvent(data: $data, EventID: $eventId)
}
    `;
export type UpdateEventMutationFn = ApolloReactCommon.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useUpdateEventMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
      }
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = ApolloReactCommon.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const ForgotPasswordDocument = gql`
    query ForgotPassword($email: String!) {
  forgotPassword(email: $email)
}
    `;

/**
 * __useForgotPasswordQuery__
 *
 * To run a query within a React component, call `useForgotPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useForgotPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useForgotPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useForgotPasswordQuery(baseOptions: ApolloReactHooks.QueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
      }
export function useForgotPasswordLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ForgotPasswordQuery, ForgotPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<ForgotPasswordQuery, ForgotPasswordQueryVariables>(ForgotPasswordDocument, options);
        }
export type ForgotPasswordQueryHookResult = ReturnType<typeof useForgotPasswordQuery>;
export type ForgotPasswordLazyQueryHookResult = ReturnType<typeof useForgotPasswordLazyQuery>;
export type ForgotPasswordQueryResult = ApolloReactCommon.QueryResult<ForgotPasswordQuery, ForgotPasswordQueryVariables>;
export function refetchForgotPasswordQuery(variables: ForgotPasswordQueryVariables) {
      return { query: ForgotPasswordDocument, variables: variables }
    }
export const GetMe_QueryDocument = gql`
    query GetMe_query {
  getMe_query {
    user_id
    saarang_id
    email_primary
    email_secondary
    username
    mobile_number_primary
    mobile_number_secondary
    positions
    DOB
    verified
    profile_picture
    college
    college_email_id
    college_location
    year_of_study
    region
  }
}
    `;

/**
 * __useGetMe_QueryQuery__
 *
 * To run a query within a React component, call `useGetMe_QueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMe_QueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMe_QueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMe_QueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMe_QueryQuery, GetMe_QueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMe_QueryQuery, GetMe_QueryQueryVariables>(GetMe_QueryDocument, options);
      }
export function useGetMe_QueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMe_QueryQuery, GetMe_QueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMe_QueryQuery, GetMe_QueryQueryVariables>(GetMe_QueryDocument, options);
        }
export type GetMe_QueryQueryHookResult = ReturnType<typeof useGetMe_QueryQuery>;
export type GetMe_QueryLazyQueryHookResult = ReturnType<typeof useGetMe_QueryLazyQuery>;
export type GetMe_QueryQueryResult = ApolloReactCommon.QueryResult<GetMe_QueryQuery, GetMe_QueryQueryVariables>;
export function refetchGetMe_QueryQuery(variables?: GetMe_QueryQueryVariables) {
      return { query: GetMe_QueryDocument, variables: variables }
    }
export const GetAllEventsDocument = gql`
    query GetAllEvents($day: Float) {
  getAllEvents(day: $day) {
    title
    id
    event_date
    club_name
  }
}
    `;

/**
 * __useGetAllEventsQuery__
 *
 * To run a query within a React component, call `useGetAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllEventsQuery({
 *   variables: {
 *      day: // value for 'day'
 *   },
 * });
 */
export function useGetAllEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(GetAllEventsDocument, options);
      }
export function useGetAllEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAllEventsQuery, GetAllEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAllEventsQuery, GetAllEventsQueryVariables>(GetAllEventsDocument, options);
        }
export type GetAllEventsQueryHookResult = ReturnType<typeof useGetAllEventsQuery>;
export type GetAllEventsLazyQueryHookResult = ReturnType<typeof useGetAllEventsLazyQuery>;
export type GetAllEventsQueryResult = ApolloReactCommon.QueryResult<GetAllEventsQuery, GetAllEventsQueryVariables>;
export function refetchGetAllEventsQuery(variables?: GetAllEventsQueryVariables) {
      return { query: GetAllEventsDocument, variables: variables }
    }
export const GetEventbyIdDocument = gql`
    query GetEventbyId($eventId: String!) {
  getEventbyId(EventId: $eventId) {
    event {
      id
      event_category
      club_name
      title
      tagline
      description
      image_url
      rules
      event_date
      judge_name
      judge_image
      submission_link
      type
      price
      event_location
      registrations_closed
    }
  }
}
    `;

/**
 * __useGetEventbyIdQuery__
 *
 * To run a query within a React component, call `useGetEventbyIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEventbyIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEventbyIdQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetEventbyIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetEventbyIdQuery, GetEventbyIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetEventbyIdQuery, GetEventbyIdQueryVariables>(GetEventbyIdDocument, options);
      }
export function useGetEventbyIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEventbyIdQuery, GetEventbyIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetEventbyIdQuery, GetEventbyIdQueryVariables>(GetEventbyIdDocument, options);
        }
export type GetEventbyIdQueryHookResult = ReturnType<typeof useGetEventbyIdQuery>;
export type GetEventbyIdLazyQueryHookResult = ReturnType<typeof useGetEventbyIdLazyQuery>;
export type GetEventbyIdQueryResult = ApolloReactCommon.QueryResult<GetEventbyIdQuery, GetEventbyIdQueryVariables>;
export function refetchGetEventbyIdQuery(variables: GetEventbyIdQueryVariables) {
      return { query: GetEventbyIdDocument, variables: variables }
    }
export const GetMyEventsDocument = gql`
    query GetMyEvents {
  getMyEvents {
    title
    event_date
    id
  }
}
    `;

/**
 * __useGetMyEventsQuery__
 *
 * To run a query within a React component, call `useGetMyEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetMyEventsQuery, GetMyEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetMyEventsQuery, GetMyEventsQueryVariables>(GetMyEventsDocument, options);
      }
export function useGetMyEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetMyEventsQuery, GetMyEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetMyEventsQuery, GetMyEventsQueryVariables>(GetMyEventsDocument, options);
        }
export type GetMyEventsQueryHookResult = ReturnType<typeof useGetMyEventsQuery>;
export type GetMyEventsLazyQueryHookResult = ReturnType<typeof useGetMyEventsLazyQuery>;
export type GetMyEventsQueryResult = ApolloReactCommon.QueryResult<GetMyEventsQuery, GetMyEventsQueryVariables>;
export function refetchGetMyEventsQuery(variables?: GetMyEventsQueryVariables) {
      return { query: GetMyEventsDocument, variables: variables }
    }
export const GetRegisteredUsersForEventDocument = gql`
    query GetRegisteredUsersForEvent($eventId: String!) {
  getRegisteredUsersForEvent(EventId: $eventId) {
    saarang_id
    user_id
    email_primary
    username
    mobile_number_primary
    college
  }
}
    `;

/**
 * __useGetRegisteredUsersForEventQuery__
 *
 * To run a query within a React component, call `useGetRegisteredUsersForEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRegisteredUsersForEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRegisteredUsersForEventQuery({
 *   variables: {
 *      eventId: // value for 'eventId'
 *   },
 * });
 */
export function useGetRegisteredUsersForEventQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRegisteredUsersForEventQuery, GetRegisteredUsersForEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRegisteredUsersForEventQuery, GetRegisteredUsersForEventQueryVariables>(GetRegisteredUsersForEventDocument, options);
      }
export function useGetRegisteredUsersForEventLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRegisteredUsersForEventQuery, GetRegisteredUsersForEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRegisteredUsersForEventQuery, GetRegisteredUsersForEventQueryVariables>(GetRegisteredUsersForEventDocument, options);
        }
export type GetRegisteredUsersForEventQueryHookResult = ReturnType<typeof useGetRegisteredUsersForEventQuery>;
export type GetRegisteredUsersForEventLazyQueryHookResult = ReturnType<typeof useGetRegisteredUsersForEventLazyQuery>;
export type GetRegisteredUsersForEventQueryResult = ApolloReactCommon.QueryResult<GetRegisteredUsersForEventQuery, GetRegisteredUsersForEventQueryVariables>;
export function refetchGetRegisteredUsersForEventQuery(variables: GetRegisteredUsersForEventQueryVariables) {
      return { query: GetRegisteredUsersForEventDocument, variables: variables }
    }
export const GetSaarangIdDocument = gql`
    query GetSaarangID {
  getMe_query {
    user_id
    saarang_id
  }
}
    `;

/**
 * __useGetSaarangIdQuery__
 *
 * To run a query within a React component, call `useGetSaarangIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSaarangIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSaarangIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSaarangIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSaarangIdQuery, GetSaarangIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSaarangIdQuery, GetSaarangIdQueryVariables>(GetSaarangIdDocument, options);
      }
export function useGetSaarangIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSaarangIdQuery, GetSaarangIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSaarangIdQuery, GetSaarangIdQueryVariables>(GetSaarangIdDocument, options);
        }
export type GetSaarangIdQueryHookResult = ReturnType<typeof useGetSaarangIdQuery>;
export type GetSaarangIdLazyQueryHookResult = ReturnType<typeof useGetSaarangIdLazyQuery>;
export type GetSaarangIdQueryResult = ApolloReactCommon.QueryResult<GetSaarangIdQuery, GetSaarangIdQueryVariables>;
export function refetchGetSaarangIdQuery(variables?: GetSaarangIdQueryVariables) {
      return { query: GetSaarangIdDocument, variables: variables }
    }
export const GetClubPassSalesDocument = gql`
    query GetClubPassSales($clubName: String!) {
  getClubPassSales(ClubName: $clubName) {
    user_id
    saarang_id
    email_primary
    username
    mobile_number_primary
    college
  }
}
    `;

/**
 * __useGetClubPassSalesQuery__
 *
 * To run a query within a React component, call `useGetClubPassSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClubPassSalesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClubPassSalesQuery({
 *   variables: {
 *      clubName: // value for 'clubName'
 *   },
 * });
 */
export function useGetClubPassSalesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetClubPassSalesQuery, GetClubPassSalesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetClubPassSalesQuery, GetClubPassSalesQueryVariables>(GetClubPassSalesDocument, options);
      }
export function useGetClubPassSalesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetClubPassSalesQuery, GetClubPassSalesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetClubPassSalesQuery, GetClubPassSalesQueryVariables>(GetClubPassSalesDocument, options);
        }
export type GetClubPassSalesQueryHookResult = ReturnType<typeof useGetClubPassSalesQuery>;
export type GetClubPassSalesLazyQueryHookResult = ReturnType<typeof useGetClubPassSalesLazyQuery>;
export type GetClubPassSalesQueryResult = ApolloReactCommon.QueryResult<GetClubPassSalesQuery, GetClubPassSalesQueryVariables>;
export function refetchGetClubPassSalesQuery(variables: GetClubPassSalesQueryVariables) {
      return { query: GetClubPassSalesDocument, variables: variables }
    }
export const GetTicketingEventsDocument = gql`
    query GetTicketingEvents {
  getTicketingEvents {
    id
    name
    slot
    price
    date
    capacity
    is_full
  }
}
    `;

/**
 * __useGetTicketingEventsQuery__
 *
 * To run a query within a React component, call `useGetTicketingEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketingEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketingEventsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTicketingEventsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTicketingEventsQuery, GetTicketingEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTicketingEventsQuery, GetTicketingEventsQueryVariables>(GetTicketingEventsDocument, options);
      }
export function useGetTicketingEventsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTicketingEventsQuery, GetTicketingEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTicketingEventsQuery, GetTicketingEventsQueryVariables>(GetTicketingEventsDocument, options);
        }
export type GetTicketingEventsQueryHookResult = ReturnType<typeof useGetTicketingEventsQuery>;
export type GetTicketingEventsLazyQueryHookResult = ReturnType<typeof useGetTicketingEventsLazyQuery>;
export type GetTicketingEventsQueryResult = ApolloReactCommon.QueryResult<GetTicketingEventsQuery, GetTicketingEventsQueryVariables>;
export function refetchGetTicketingEventsQuery(variables?: GetTicketingEventsQueryVariables) {
      return { query: GetTicketingEventsDocument, variables: variables }
    }
export const GetTicketingRegsBySlotDocument = gql`
    query GetTicketingRegsBySlot($slotId: String!) {
  getTicketingRegsBySlot(SlotId: $slotId) {
    username
    mobile_number_primary
    email_primary
    college
    quantity
  }
}
    `;

/**
 * __useGetTicketingRegsBySlotQuery__
 *
 * To run a query within a React component, call `useGetTicketingRegsBySlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketingRegsBySlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketingRegsBySlotQuery({
 *   variables: {
 *      slotId: // value for 'slotId'
 *   },
 * });
 */
export function useGetTicketingRegsBySlotQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTicketingRegsBySlotQuery, GetTicketingRegsBySlotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTicketingRegsBySlotQuery, GetTicketingRegsBySlotQueryVariables>(GetTicketingRegsBySlotDocument, options);
      }
export function useGetTicketingRegsBySlotLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTicketingRegsBySlotQuery, GetTicketingRegsBySlotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTicketingRegsBySlotQuery, GetTicketingRegsBySlotQueryVariables>(GetTicketingRegsBySlotDocument, options);
        }
export type GetTicketingRegsBySlotQueryHookResult = ReturnType<typeof useGetTicketingRegsBySlotQuery>;
export type GetTicketingRegsBySlotLazyQueryHookResult = ReturnType<typeof useGetTicketingRegsBySlotLazyQuery>;
export type GetTicketingRegsBySlotQueryResult = ApolloReactCommon.QueryResult<GetTicketingRegsBySlotQuery, GetTicketingRegsBySlotQueryVariables>;
export function refetchGetTicketingRegsBySlotQuery(variables: GetTicketingRegsBySlotQueryVariables) {
      return { query: GetTicketingRegsBySlotDocument, variables: variables }
    }
export const GetTicketingSalesStatsDocument = gql`
    query GetTicketingSalesStats {
  getTicketingSalesStats {
    event_id
    date
    slot
    quantity_sold
  }
}
    `;

/**
 * __useGetTicketingSalesStatsQuery__
 *
 * To run a query within a React component, call `useGetTicketingSalesStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTicketingSalesStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTicketingSalesStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTicketingSalesStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTicketingSalesStatsQuery, GetTicketingSalesStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTicketingSalesStatsQuery, GetTicketingSalesStatsQueryVariables>(GetTicketingSalesStatsDocument, options);
      }
export function useGetTicketingSalesStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTicketingSalesStatsQuery, GetTicketingSalesStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTicketingSalesStatsQuery, GetTicketingSalesStatsQueryVariables>(GetTicketingSalesStatsDocument, options);
        }
export type GetTicketingSalesStatsQueryHookResult = ReturnType<typeof useGetTicketingSalesStatsQuery>;
export type GetTicketingSalesStatsLazyQueryHookResult = ReturnType<typeof useGetTicketingSalesStatsLazyQuery>;
export type GetTicketingSalesStatsQueryResult = ApolloReactCommon.QueryResult<GetTicketingSalesStatsQuery, GetTicketingSalesStatsQueryVariables>;
export function refetchGetTicketingSalesStatsQuery(variables?: GetTicketingSalesStatsQueryVariables) {
      return { query: GetTicketingSalesStatsDocument, variables: variables }
    }