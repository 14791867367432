import { useEffect , useState } from 'react';
import './map.scss';






function Map() {

    const [Landscape, setLandscape] = useState(false);

    useEffect(() => {
        setLandscape(window.innerWidth>window.innerHeight);
    }, [window.innerWidth]);
    return ( 
        <div className="map-container">
            <img src="https://d1kbtnj9hqaggx.cloudfront.net/map.webp" style={{height:(Landscape)?"100vh":"auto" , width:(Landscape)?"auto":"100vw"}}/>
        </div>
     );
}

export default Map;