import axios from "axios";
import React, { useState } from "react";
import "./Schedule.css";
import {
  useGetAllEventsLazyQuery,
  useGetAllEventsQuery,
} from "../../generated/graphql";
import { FiAlertTriangle } from "react-icons/fi";

axios.defaults.withCredentials = true;

interface event {
  id: string;
  event_date: any;
  title: string;
  club_name: string;
}

interface resp {
  events: event[];
}

const Schedule = () => {
  const [isOpen, setIsOpen] = useState(false);

  // SET 3 seconds delay for popup

  React.useEffect(() => {
    setTimeout(() => {
      setIsOpen(true);
    }, 1500);
  }, []);

  const togglePopup = () => {
    setIsOpen(!isOpen);
  };

  const [active, setActive] = React.useState<string>("events");
  const [currentDay, setCurrentDay] = React.useState<number>(11);
  const [currentShowDay, setCurrentShowDay] = React.useState<number>(11);
  const [displayDay, setDisplayDay] = React.useState<string>("day11");
  const [events, setEvents] = React.useState<event[]>([]);
  const [showDate, setShowDate] = React.useState<boolean>(true);
  const extractDateTime = (dateTime: string | undefined) => {
    if (dateTime) {
      const date = new Date(dateTime);
      let month = date.toUTCString().split(" ")[2];
      const day = date.getUTCDate();

      // const hours = date.getUTCHours();
      // const minutes = date.getUTCMinutes();
      // const ampm = hours >= 12 ? "PM" : "AM";
      // const hour = hours % 12;
      // const min = minutes < 10 ? "0" + minutes : minutes;

      if (showDate) {
        // return `${month} ${day}, ${hour}:${min} ${ampm}`;
        return `${month} ${day}`;
      }

      // return `${hour}:${min} ${ampm}`;
    }
  };

  const [
    getAllEvents,
    { data: lazyData, loading: Lazyloading, error: Lazyerror },
  ] = useGetAllEventsLazyQuery({
    variables: {},
  });

  React.useEffect(() => {
    handleLazyQuery(11);
  }, []);

  const handleLazyQuery = (day: number) => {
    setCurrentDay(day);
    setShowDate(false);
    // Remove active class from all days
    var r_elements = document.getElementsByClassName("day");
    for (var i = 0; i < r_elements.length; i++) {
      r_elements[i].classList.remove("day-active");
    }
    // Get document by id
    var elements = document.getElementById("day" + day);
    if (elements) {
      elements.classList.add("day-active");
    }
    getAllEvents({
      variables: {
        day: day,
      },
    });
  };

  React.useEffect(() => {
    var element = document.getElementsByClassName("button-events");
    if (element) {
      element[0].classList.add("button-active");
    }
    if (lazyData) {
      setEvents(lazyData.getAllEvents);
    }
  }, [lazyData]);

  const handleChange = (e: any) => {
    if (e === "events") {
      if (active === "Worldfest") {
        setActive("events");
      }
    } else {
      if (active === "events") {
        setActive("Worldfest");
        // handleDateChange(11);
      }
    }
  };

  const handleDateChange = (day: number) => {
    setCurrentShowDay(day);
    setDisplayDay("day" + day);
    // Remove active class from all days
    var r_elements = document.getElementsByClassName("day");
    for (var i = 0; i < r_elements.length; i++) {
      r_elements[i].classList.remove("day-active");
    }
    // Get document by id
    var elements = document.getElementById("day" + day);
    if (elements) {
      elements.classList.add("day-active");
    }
  };

  React.useEffect(() => {
    var elements;
    if (active === "events") {
      elements = document.getElementById("day" + currentDay);
    } else {
      elements = document.getElementById("day" + currentShowDay);
    }
    var r_elements = document.getElementsByClassName("day");
    // console.log(elements, "Elements");
    // console.log(r_elements.length, "r_elements");
    console.log("Hello");
    for (var i = 0; i < r_elements.length; i++) {
      r_elements[i].classList.remove("day-active");
    }
    if (elements) {
      elements.classList.add("day-active");
      // console.log("Added");
      // console.log("Hi");
    }
  }, [active]);

  return (
    <div className="schedule-page">
      {active === "events" && (
        <div className="launching_text_s">
          {isOpen && (
            <div className="popup-box">
              <div className="popup-contentbox">
                <FiAlertTriangle className="icon" />
                <ul>
                  Event Registration is free for IITM students. Login with your
                  IITM email id to register.
                </ul>
              </div>
              <button
                id="close-button"
                onClick={() => {
                  togglePopup();
                }}
              >
                x
              </button>
            </div>
          )}
          <div className="schedule-head">SCHEDULE</div>

          <div className="schedule-pdf"><a href="https://saarang2024.s3.ap-south-1.amazonaws.com/Saarang24_Schedule+.pdf" target="_blank">Download Schedule with Time and Venue</a></div>
          <div className="change-data">
            <button
              className="button-events button-active"
              onClick={() => {
                handleChange("events");
              }}
            >
              <div className="events-title">Events</div>
            </button>
            <button
              className="button-Worldfest"
              onClick={() => {
                handleChange("Worldfest");
              }}
            >
              <div className="Worldfest-title">Shows</div>
            </button>
          </div>
          <div className="day-list">
            <div
              className="day"
              id="day10"
              onClick={() => {
                handleLazyQuery(10);
              }}
            >
              Jan 10{" "}
            </div>
            <div
              className="day"
              id="day11"
              onClick={() => {
                handleLazyQuery(11);
              }}
            >
              Jan 11{" "}
            </div>
            <div
              className="day"
              id="day12"
              onClick={() => {
                handleLazyQuery(12);
              }}
            >
              Jan 12
            </div>
            <div
              className="day"
              id="day13"
              onClick={() => {
                handleLazyQuery(13);
              }}
            >
              Jan 13
            </div>
            <div
              className="day"
              id="day14"
              onClick={() => {
                handleLazyQuery(14);
              }}
            >
              Jan 14
            </div>
          </div>
          {/* <div className="schedule-head">EVENT SCHEDULE</div> */}
          {Lazyloading ? (
            <div
              className="loading"
              style={{ textAlign: "center", width: "100%" }}
            >
              Loading...
            </div>
          ) : (
            <table id="schedule">
              {events.map((event, index) => {
                return (
                  <tr className="event-row">
                    <td style={{ minWidth: "30px" }}>{index + 1}</td>
                    <td>
                      <p className="event-title">{event.title}</p>{" "}
                      <p className="club-name">{event.club_name}</p>
                    </td>
                    <td>{extractDateTime(event.event_date)}</td>
                    <td>
                      <a
                        href={`https://saarang.org/event/${event.id}`}
                        target="_blank"
                        className="register-event"
                      >
                        Register
                      </a>
                    </td>
                  </tr>
                );
              })}
            </table>
          )}
        </div>
      )}
      {active === "Worldfest" && (
        <div className="launching_text_s">
          <div className="schedule-head">SCHEDULE</div>
          <div className="change-data">
            <button
              className="button-events"
              onClick={() => {
                handleChange("events");
              }}
            >
              <div className="events-title">Events</div>
            </button>
            <button
              className="button-Worldfest button-active"
              onClick={() => {
                handleChange("Worldfest");
              }}
            >
              <div className="Worldfest-title">Shows</div>
            </button>
          </div>
          <div className="day-list">
            <div
              className="day"
              id="day10"
              onClick={() => {
                handleDateChange(10);
              }}
            >
              Jan 10{" "}
            </div>
            <div
              className="day"
              id="day11"
              onClick={() => {
                handleDateChange(11);
              }}
            >
              Jan 11{" "}
            </div>
            <div
              className="day"
              id="day12"
              onClick={() => {
                handleDateChange(12);
              }}
            >
              Jan 12
            </div>
            <div
              className="day"
              id="day13"
              onClick={() => {
                handleDateChange(13);
              }}
            >
              Jan 13
            </div>
            <div
              className="day"
              id="day14"
              onClick={() => {
                handleDateChange(14);
              }}
            >
              Jan 14
            </div>
          </div>
          {displayDay === "day10" && (
            <table id="schedule">
              {/* <tr className="event-row">
                <td style={{ minWidth: "30px" }}>1</td>
                <td>
                  <p className="event-title">Subterranean Masquerade</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>1:15 PM - 1:40 PM</td>
                <td>SAC</td>
              </tr> */}
              {/* <tr className="event-row">
                <td style={{ minWidth: "30px" }}>2</td>
                <td>
                  <p className="event-title">Motorpyscho</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>1:40 PM - 2:30 PM</td>
                <td>SAC</td>
              </tr> */}
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>1</td>
                <td>
                  <p className="event-title">CLASSICAL NIGHT</p>
                  <p className="club-name">Proshow</p>
                </td>
                <td>6:00 PM</td>
                <td>OAT</td>
              </tr>
            </table>
          )}
          {displayDay === "day11" && (
            <table id="schedule">
              {/* <tr className="event-row">
                <td style={{ minWidth: "30px" }}>2</td>
                <td>
                  <p className="event-title">Void (graffiti)</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>10:00 AM - 5:00 PM</td>
                <td>KV Tent</td>
              </tr> */}
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>1</td>
                <td>
                  <p className="event-title">CHOREO NITE</p>
                  <p className="club-name">Proshow</p>
                </td>
                <td>7:00 PM</td>
                <td>OAT</td>
              </tr>
            </table>
          )}
          {displayDay === "day12" && (
            <table id="schedule">
              {/* <tr className="event-row">
                <td style={{ minWidth: "30px" }}>1</td>
                <td>
                  <p className="event-title">Manzo Soul</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>9:30 AM - 10:00 AM</td>
                <td>KV Stage</td>
              </tr> */}
              {/* <tr className="event-row">
                <td style={{ minWidth: "30px" }}>2</td>
                <td>
                  <p className="event-title">Neon Dreams</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>12:00 PM - 1:00 PM</td>
                <td>KV Stage</td>
              </tr> */}
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>1</td>
                <td>
                  <p className="event-title">The Impatient Sisters</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>1:30 PM</td>
                <td>KV Grounds</td>
              </tr>
              
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>2</td>
                <td>
                  <p className="event-title">Oh, Flamingo</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>2:40 PM</td>
                <td> KV Grounds </td>
              </tr>
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>3</td>
                <td>
                  <p className="event-title">Lunarsea</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>3:50 PM</td>
                <td> KV Grounds</td>
              </tr>
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>4</td>
                <td>
                  <p className="event-title">EDM NIGHT</p>
                  <p className="club-name">Proshow</p>
                </td>
                <td>6:00 PM</td>
                <td>OAT</td>
              </tr>
            </table>
          )}
          {displayDay === "day13" && (
            <table id="schedule">
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>1</td>
                <td>
                  <p className="event-title">Aaron Bunac</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>3:30 PM</td>
                <td>KV Grounds</td>
              </tr>
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>2</td>
                <td>
                  <p className="event-title">Dhamal World Collective</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>4:30 PM</td>
                <td>KV Grounds</td>
              </tr>
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>3</td>
                <td>
                  <p className="event-title">Comedy SHow</p>
                  <p className="club-name">Proshow</p>
                </td>
                <td> 2:00 PM </td>
                <td> SAC </td>
              </tr>
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>4</td>
                <td>
                  <p className="event-title">ROCK NIGHT</p>
                  <p className="club-name">Proshow</p>
                </td>
                <td>6:00 PM</td>
                <td>OAT</td>
              </tr>
            </table>
          )}
          {displayDay === "day14" && (
            <table id="schedule">
              {/* <tr className="event-row">
                <td style={{ minWidth: "30px" }}>1</td>
                <td>
                  <p className="event-title">Void (graffiti)</p>
                  <p className="club-name">Worldfest</p>
                </td>
                <td>11:00 AM - 12:30 PM</td>
                <td>RMN 202</td>
              </tr> */}
              <tr className="event-row">
                <td style={{ minWidth: "30px" }}>1</td>
                <td>
                  <p className="event-title">POP NIGHT</p>
                  <p className="club-name">Proshow</p>
                </td>
                <td>6:00 PM</td>
                <td>OAT</td>
              </tr>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default Schedule;
