import { useState } from "react";
import { motion } from "framer-motion";
import Links from "./links/Links";
import "./sidebar.scss";
import ToggleButton from "./toggleButton/ToggleButton";
import { useLocation } from "react-router-dom";

const variants = {
  open: {
    clipPath: "circle(1200px at 30px 50px)",
    transition: {
      type: "spring",
      stiffness: 20,
    },
  },
  closed: {
    clipPath: "circle(20px at 30px 50px)",
    transition: {
      delay: 0.5,
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};
const Sidebar = () => {

  const location = useLocation();
    const currentPath = location.pathname;

  const [open, setOpen] = useState(false);

  return (
    <motion.div className="sidebar" style={currentPath==='/'?{display:'none'} :{display:'block'}} animate={open ? "open" : "closed"}>
      <motion.div className="bg" variants={variants}>
        <Links />
      </motion.div>
      <ToggleButton setOpen={setOpen} />
    </motion.div>
  );
};

export default Sidebar;