import React , {useState , useEffect , useRef } from "react";
import "./spotlight.scss";
import { motion } from "framer-motion";

function Spotlight() {

  const [positionIndexes, setPositionIndexes] = useState([0, 1, 2, 3, 4]);
    const [isInView, setIsInView] = useState(false);
    const carouselRef = useRef(null); // Ref for the carousel element
  
    const lap=(window.innerWidth > 738);
  
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => {
          setIsInView(entry.isIntersecting);
        },
        { threshold: 0.} // Adjust this value based on how much of the element should be in view to trigger autoplay
      );
  
      if (carouselRef.current) {
        observer.observe(carouselRef.current);
      }
  
      return () => {
        if (carouselRef.current) {
          observer.unobserve(carouselRef.current);
        }
      };
    }, [carouselRef]);


  const images =[
    "https://iili.io/J71Pmxt.webp",
    "https://iili.io/J71iHfs.webp",
    "https://iili.io/J71Pysn.webp",
    "https://iili.io/J7O83WQ.webp",
    "https://iili.io/J7O82zx.webp",
    "https://iili.io/J78RMQf.webp",
    "https://iili.io/J768KIp.webp", 
  ];


  return (
    <div className="spotlight-container" ref={carouselRef}>
      <div className="heading"> SPOTLIGHT</div>
      <div className="cards-container">
                {images.map((article, i) => (
                    <motion.div
                    key={i}
                    className='main-card'
                    initial={{
                      opacity: 0,
                      // translateX: -100,
                      translateY: 100,
                      rotateY: -180,
                    }}
                    animate={{
                      opacity: isInView ? 1 : 0,
                      // translateX: isInView ? 0 : -100,
                      translateY: isInView ? 0 : 100,
                      rotateY: isInView ? 0 : -180,
                      transition: { delay: i * 0.4 },
                    }}
                    transition={{ type: 'spring', bounce: 0.1 }}
                  >
                    <img
                      src={article}
                      className='card-image'
                      
                    />
                  </motion.div> 
                ))}

            </div>
      
    </div>
  );
}

export default Spotlight;