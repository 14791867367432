import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
  createHttpLink,
} from "@apollo/client";
import client from "./Graphql";
import { AuthContextProvider } from "./contexts/AuthContext";
import { PortalContextProvider } from "./contexts/PortalContext";

console.log("temp branch of the main-frontend repo for testing")

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
    <AuthContextProvider>
      <PortalContextProvider>
     <App />
     </PortalContextProvider>
     </AuthContextProvider>
    </ApolloProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
