import React, { useState , useEffect } from 'react';
import axios from 'axios';
import AuthContext from '../../contexts/AuthContext';
import useRazorpay from 'react-razorpay';
import './combos.scss';


interface RazorpayOptions {
    key: string;
    amount: string;
    currency: string;
    name: string;
    description: string;
    image?: string;
    payment_capture?: string;
    order_id: string;
    handler: (res: any) => void;
    //   modal: {
    //     ondismiss: () => void;
    //   };
}

interface RazorpayResponse {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
}



function Combos({setCombodisp}:{setCombodisp:any}) {

    const Razorpay = useRazorpay();
    const authContext = React.useContext(AuthContext);

    const handlePayment = React.useCallback(
        (name:string) => {

            try {
                axios({
                    url: "https://saarang.org/api2/",
                    // url: "http://localhost:8001/graphql",
                    method: "post",
                    data: {
                        query: `
                    mutation BuyClubPass($name: String!) {
                        buyClubPass(name: $name) {
                          id
                          amount
                        }
                      }
                    `,
                        variables: {
                            name,
                        },
                    },
                }).then((result) => {
                    if (result.data.errors) {
                        alert(result.data.errors[0].message);
                        return;
                    }

                    const rp_data = result.data.data.buyClubPass;

                    const options: RazorpayOptions = {
                        key: "rzp_live_VgVO9uzPsxViN5",
                        // key: "rzp_test_quPGk7TZddItFB",
                        amount: String(rp_data.amount),
                        currency: "INR",
                        name: "Saarang 2023",
                        description: "Payment Transaction",
                        payment_capture: "true",
                        // image: "https://example.com/your_logo",
                        order_id: rp_data.id,
                        handler: async function (response: RazorpayResponse) {
                            // setPaymentID(res.razorpay_payment_id);
                            // setSignatureID(res.razorpay_signature);
                            // console.log(response);
                            const data = {
                                razorpayPaymentId: response.razorpay_payment_id,
                                razorpayOrderId: response.razorpay_order_id,
                                razorpaySignature: response.razorpay_signature,
                            };

                            const res = await axios({
                                url: "https://saarang.org/api2/",
                                // url: "http://localhost:8001/graphql",
                                method: "post",
                                data: {
                                    query: `
                        mutation Verify_signature($razorpayOrderId: String!, $razorpayPaymentId: String!, $razorpaySignature: String!) {
                            verify_signature(razorpay_order_id: $razorpayOrderId, razorpay_payment_id: $razorpayPaymentId, razorpay_signature: $razorpaySignature)
                          }
                        `,
                                    variables: {
                                        razorpayOrderId: data.razorpayOrderId,
                                        razorpayPaymentId: data.razorpayPaymentId,
                                        razorpaySignature: data.razorpaySignature,
                                    },
                                },
                            });

                            // console.log(res.data.data.verify_signature);
                            if (res.data.data.verify_signature == true) {
                                alert(`${name} combo pass purchase successful`);
                            }
                        },
                    };

                    const rzp1 = new Razorpay(options);
                    rzp1.open();
                });
            } catch (err) {
                alert(err);
                console.log(err);
            }
        },
        [Razorpay]
    );

    


   



    return ( 
        <div className="combos-container" >
            <button className='close-button' onClick={()=>{setCombodisp(false)}}> X </button>

        
        <div className="combo">
            <div className="combo-heading">Artistry Pass</div>
            <div className="combo-clubs">Finearts + Designvfx</div>
            <button className='combo-button' onClick={() => { handlePayment("artistry")}}> Rs 259 </button>
        </div>
        
        
        <div className="combo">
            <div className="combo-heading">Wordsmith Pass</div>
            <div className="combo-clubs">Oratory + Wordgames + Writing</div>
            <button className='combo-button' onClick={() => { handlePayment("wordsmith")}}> Rs 699 </button>
        </div> 
        
        <div className="combo">
            <div className="combo-heading">Mavrick Pass</div>
            <div className="combo-clubs">Informals + Gaming</div>
            <button className='combo-button' onClick={() => { handlePayment("mavrick")}}> Rs 299 </button>
        </div>
       
        <div className="combo">
            <div className="combo-heading">Symphony Pass</div>
            <div className="combo-clubs">Music + Classical Arts</div>
            <button className='combo-button' onClick={() => { handlePayment("symphony")}}> Rs 399 </button>
        </div>
       

 

        </div>
     );
}

export default Combos;