import React, { useRef, useState } from 'react'
import axios from 'axios';
import './event_category.scss'
import useRazorpay from 'react-razorpay';
import AuthContext from '../../../contexts/AuthContext';
import { FiAlertTriangle } from 'react-icons/fi';
import { useGetAllEventsQuery } from '../../../generated/graphql';
import { motion, useInView } from 'framer-motion';
import Combos from '../../../components/combos/combos';

interface RazorpayOptions {
    key: string;
    amount: string;
    currency: string;
    name: string;
    description: string;
    image?: string;
    payment_capture?: string;
    order_id: string;
    handler: (res: any) => void;
    //   modal: {
    //     ondismiss: () => void;
    //   };
}

interface RazorpayResponse {
    razorpay_payment_id: string;
    razorpay_order_id: string;
    razorpay_signature: string;
}

const EventCategory = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [Combodisp, setCombodisp] = useState(false);
    const [eventName, setEventName] = useState<string | undefined>('');
    const eventGet = useRef<null | HTMLInputElement>(null);


    const lap=(window.innerWidth > 738);

    const event_categories =[
        { id:1, eventcategory:'PERFORMING ARTS',img:'https://iili.io/JA3OfUB.jpg',url:'events/performing'},
        { id:2, eventcategory:'LITERARY ARTS',img:'https://iili.io/JA3OoDg.jpg',url:'events/literary'},
        { id:3, eventcategory:'CREATIVE ARTS',img:'https://iili.io/JA3OKlV.jpg',url:'events/creative'},
        { id:4, eventcategory:'DIGITAL ARTS',img:'https://iili.io/JA3OCR1.png',url:'events/digital'},
        { id:5, eventcategory:'NOVA',img:'https://iili.io/JA3OnOF.png',url:'events/nova'},
        { id:6, eventcategory:'NON-COMPETITIVE EVENTS',img:'https://iili.io/JA3ORlp.png',url:'events/noncompetitive'},
        // { id:7, eventcategory:'MERAKI',img:'https://iili.io/JA3Ozxa.jpg',url:'tbd'},
    ];

    const ref=useRef<HTMLInputElement>(null);
    const isInView = useInView(ref, { margin: "-100px" });


    const handleEvent = () => {

        const eventRef = eventGet.current;
        var event = document.getElementById(eventRef ? eventRef.value : 'DUMMYID');

        if (!event?.dataset.value)
            return;

        window.location.href = "/event/" + event?.dataset.value;
    }

    // SET 3 seconds delay for popup
    setTimeout(() => {
        setIsOpen(true);
    }, 3000);


    const togglePopup = () => {

        setIsOpen(!isOpen);
    }

    const { data: eventsData, loading: eventsLoading, error: eventsError } = useGetAllEventsQuery({
        variables: {
        },
    });

    const Razorpay = useRazorpay();
    const authContext = React.useContext(AuthContext);

    const handlePayment = React.useCallback(
        (name: string) => {

            try {
                axios({
                    url: "https://saarang.org/api2/",
                    // url: "http://localhost:8001/graphql",
                    method: "post",
                    data: {
                        query: `
                    mutation BuyClubPass($name: String!) {
                        buyClubPass(name: $name) {
                          id
                          amount
                        }
                      }
                    `,
                        variables: {
                            name,
                        },
                    },
                }).then((result) => {
                    if (result.data.errors) {
                        alert(result.data.errors[0].message);
                        return;
                    }

                    const rp_data = result.data.data.buyClubPass;

                    const options: RazorpayOptions = {
                        key: "rzp_live_VgVO9uzPsxViN5",
                        // key: "rzp_test_quPGk7TZddItFB",
                        amount: String(rp_data.amount),
                        currency: "INR",
                        name: "Saarang 2023",
                        description: "Payment Transaction",
                        payment_capture: "true",
                        // image: "https://example.com/your_logo",
                        order_id: rp_data.id,
                        handler: async function (response: RazorpayResponse) {
                            // setPaymentID(res.razorpay_payment_id);
                            // setSignatureID(res.razorpay_signature);
                            // console.log(response);
                            const data = {
                                razorpayPaymentId: response.razorpay_payment_id,
                                razorpayOrderId: response.razorpay_order_id,
                                razorpaySignature: response.razorpay_signature,
                            };

                            const res = await axios({
                                url: "https://saarang.org/api2/",
                                // url: "http://localhost:8001/graphql",
                                method: "post",
                                data: {
                                    query: `
                        mutation Verify_signature($razorpayOrderId: String!, $razorpayPaymentId: String!, $razorpaySignature: String!) {
                            verify_signature(razorpay_order_id: $razorpayOrderId, razorpay_payment_id: $razorpayPaymentId, razorpay_signature: $razorpaySignature)
                          }
                        `,
                                    variables: {
                                        razorpayOrderId: data.razorpayOrderId,
                                        razorpayPaymentId: data.razorpayPaymentId,
                                        razorpaySignature: data.razorpaySignature,
                                    },
                                },
                            });

                            // console.log(res.data.data.verify_signature);
                            if (res.data.data.verify_signature == true) {
                                alert("ALL EVENTS PASS Purchase successful");
                            }
                        },
                    };

                    const rzp1 = new Razorpay(options);
                    rzp1.open();
                });
            } catch (err) {
                alert(err);
                console.log(err);
            }
        },
        [Razorpay]
    );

    return (
    <motion.div className='event-categories' ref={ref}>

<video  className='event-bg' autoPlay muted={true}  height={lap?"auto":"100%"} width={lap?'100%':'auto'} loop  controls={false} playsInline>
          <source src={(lap)?'https://d1kbtnj9hqaggx.cloudfront.net/events_bg_pc.mp4':'https://d1kbtnj9hqaggx.cloudfront.net/events_bg_mob.mp4'}type="video/mp4"  />
          Your browser does not support the video tag.
        </video>

            <div className='button-group'>


            <a href='https://saarang.org/registeredevents' className='registered-events'>My Registrations <i className='arrow button-right'></i> </a>
            {
                // Check if user email not ends with @smail.iitm.ac.in
                authContext && (!authContext.state.user || (authContext.state.user && authContext.state.user.email_primary.split('@')[1] !== 'smail.iitm.ac.in')) && (
                    <button className='allevents-pass' onClick={() => { handlePayment("allevents") }}>GET ALL EVENTS PASS 799/-</button>
                    )
                }
            
            {
                // Check if user email not ends with @smail.iitm.ac.in
                authContext && (!authContext.state.user || (authContext.state.user && authContext.state.user.email_primary.split('@')[1] !== 'smail.iitm.ac.in')) && (
                    <button className='combo-pass' onClick={() => {setCombodisp(!Combodisp)}}>Get Combo Passes</button>
                    )
                }
            
                {Combodisp?<Combos setCombodisp={setCombodisp} />:<></>}

            <div className='search-bar'>
                <input list='events' onChange={(e) => setEventName(e.target.value)} required ref={eventGet} placeholder='Search event'/>
                <datalist id="events">
                    {eventsData?.getAllEvents.map((event) => {
                        return <option value={event.title} id={event.title} data-value={event.id} ></option>
                    })}
                </datalist>
                <button onClick={() => { handleEvent() }}><i className='go-button'></i>GO </button>
            </div>

            </div>


            {/* {isOpen &&
                <div className="popup-box pop-event">
                    <div className="popup-contentbox">
                        <FiAlertTriangle className="icon" />
                        <ul>
                            Event Registration is free for IITM students. Login with your IITM email id to register.
                        </ul>
                    </div>
                    <button id="close-button" onClick={() => { togglePopup() }} >x</button>
                </div>
            } */}
            <div className="events-heading">Events & Workshops</div>
            <div className="cards" >
            {event_categories.map((article, i) => (
                <motion.div
                key={article.id}
                className='main-card'
                initial={{
                    opacity: 0,
                    translateX:  -100 ,
                    translateY: 100,
                }}
                animate={isInView &&{ opacity: 1, translateX: 0, translateY: 0 }}
                transition={{type:"spring", bounce:0.5, delay: i * 0.2 }}
                >
                <a href={article.url}>
                    <img src={article.img}/>  
                    <div>{article.eventcategory}</div>
                
                </a>
                </motion.div>
            ))}
            </div>
        </motion.div>
    )
}

export default EventCategory