import { useRef } from "react";
import { motion, useScroll, useTransform ,useInView} from "framer-motion";

const About = ({ type }) => {
  const ref = useRef();


  const lap=(window.innerWidth > 738);

  

  const variants = {
    initial: {
      // y:1000,
      scale:10,
      opacity: 0,
    },
    animate: {
      // y: 0,
      scale:1,
      opacity: 1,
      transition: {
        
        duration: 1,
      },
    },
  };

  const isInView = useInView(ref, { margin: "-100px" });

  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });
  

  const yText = useTransform(scrollYProgress, [0, 1], ["-1%", "200%"]);

  return (
    <motion.div
      className="about-page"
      ref={ref}
        
    >
      <video  className='about-bg' autoPlay muted={true}  height={lap?"auto":"100%"} width={lap?'100%':'auto'} loop  controls={false} playsInline>
          <source src={(lap)?'https://d1kbtnj9hqaggx.cloudfront.net/AfterMovie.mp4':'https://d1kbtnj9hqaggx.cloudfront.net/AfterMovie+-+(9x16).mp4'}type="video/mp4"  />
          Your browser does not support the video tag.
        </video>
      <motion.div variants={variants} initial='initial' animate={isInView && 'animate'}
      className="about-heading"
      style={{ x: yText }}> 

        About Saarang
      </motion.div>
      <motion.div className="about-text" style={{x:yText}} variants={variants}  initial='initial' animate={isInView && "animate"}>
      Saarang, acclaimed as India's largest student-run festival and distinguished by its ISO certification, draws an impressive footfall
       of over 80,000 people. The festival is celebrated for hosting over 100 diverse events, including five professional shows that have
      featured musical luminaries such as Farhan Akhtar, Opeth, and Shankar Mahadevan. It showcases a wide array of genres, including Rock, Classical, EDM, and Pop.
      This year marks the 50th edition of Saarang, scheduled to be held offline from January 10th to 14th. The lineup includes renowned artists like Farhan Akhtar,
       Matisse & Sadko, and Thaikudam Bridge. Following last year's success , which drew a global audience, this year's event is anticipated
        to harness even greater potential and deliver an unforgettable experience.
      </motion.div>
    </motion.div>
  );
};

export default About;